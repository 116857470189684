import axios from 'axios';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const api = axios.create({
  baseURL: 'https://databasecgpt.mktmarketingdigital.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Asegura que las cookies se envían con cada solicitud
});

// Interceptor para agregar el token de autorización desde localStorage si existe
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    console.log('Token obtenido de localStorage:', token);

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('Token agregado al encabezado Authorization:', config.headers['Authorization']);
    } else {
      console.warn('No se encontró token en localStorage.');
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Manejo de respuestas y redirección en caso de error
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.error('Error 401: No autorizado. El token puede ser inválido o haber expirado.');
        console.log('Detalles del error 401:', error.response.data); // Log de detalles del error

        localStorage.removeItem('token');
        delete api.defaults.headers.common['Authorization'];
        history.push('/login');  // Redirigir al login
      } else if (error.response.status === 419) {
        console.error('Error 419: Token CSRF inválido.');
        alert('Hubo un problema de seguridad. Por favor, recarga la página e intenta nuevamente.');
      } else {
        console.error(`Error ${error.response.status}:`, error.response.data);
      }
    } else if (error.request) {
      console.error('No se recibió respuesta del servidor:', error.request);
    } else {
      console.error('Error configurando la solicitud:', error.message);
    }
    return Promise.reject(error);
  }
);

// Funciones de API

export const login = async (email, password) => {
  try {
    const response = await api.post('/login', { email, password });
    const token = response.data.token;
    console.log('Token recibido después del login:', token); // Log del token recibido

    if (token) {
      localStorage.setItem('token', token); // Guardar token en localStorage
      localStorage.setItem('user_id', response.data.user_id); // Guardar user_id en localStorage
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return token;
    } else {
      throw new Error('Token no encontrado en la respuesta');
    }
  } catch (error) {
    console.error('Error durante el login:', error);
    throw error;
  }
};


// Función para obtener notas
export const getNotes = async () => {
  try {
    const response = await api.get('/notes');
    return response.data.length > 0 ? response.data : []; // Retorna un array vacío si no hay notas
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Error obteniendo notas: No autorizado.');
    } else {
      console.error('Error obteniendo notas:', error);
    }
    throw error;
  }
};

// Función para crear un subtema (Subnote)
export const createSubnote = async (subnoteData) => {
  try {
    // Generar un subnote_id basado en la fecha y hora actual
    const subnoteId = Date.now().toString();  // Puedes usar cualquier otro método para generar un ID único si es necesario

    // Añadir el subnote_id generado al objeto subnoteData
    const dataToSend = { ...subnoteData, subnote_id: subnoteId };

    console.log('Datos a enviar al backend para subnote:', dataToSend);
    
    // Enviar los datos al backend
    const response = await api.post('/subnotes', dataToSend);

    if (!response.data || !response.data.id) {
      throw new Error('La subnota no fue creada correctamente. La respuesta no contiene un ID.');
    }

    console.log('Respuesta del servidor al crear la subnota:', response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Error 500: Error interno del servidor.', error.response.data);
    } else if (error.response && error.response.status === 401) {
      console.error('Error creando la subnota: No autorizado.');
    } else {
      console.error('Error creando la subnota:', error.response ? error.response.data : error.message);
    }
    throw error;
  }
};


// services/api.js

export const deleteSubnote = async (subnoteId) => {
  try {
    const response = await api.delete(`/subnotes/${subnoteId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error al eliminar la subnota');
  }
};



// Función para registrar un nuevo usuario
export const register = async (name, email, password, password_confirmation) => {
  try {
    const response = await api.post('/register', { name, email, password, password_confirmation });
    return response.data;
  } catch (error) {
    console.error('Error durante el registro:', error);
    throw error;
  }
};

// Función para obtener estructuras
export const getStructures = async () => {
  try {
    const userId = localStorage.getItem('user_id'); // Obtén el user_id desde localStorage
    if (!userId) {
      console.error('User ID no encontrado en localStorage.');
      return [];
    }

    const response = await api.get(`/structures?user_id=${userId}`);
    return response.data.length > 0 ? response.data : []; // Retorna un array vacío si no hay estructuras
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Error obteniendo estructuras: No autorizado.');
    } else {
      console.error('Error obteniendo estructuras:', error);
    }
    throw error;
  }
};

// Función para crear una nota
export const createNote = async (noteData) => {
  try {
    console.log('Datos a enviar al backend:', noteData);
    const response = await api.post('/notes', noteData);
    console.log('Respuesta del servidor al crear la nota:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al crear la nota:', error.response ? error.response.data : error.message);
    throw error;
  }
};

// Función para crear una estructura
export const createStructure = async (structure) => {
  try {
    console.log('Datos a enviar al backend:', structure);
    const response = await api.post('/structures', structure);
    
    if (!response.data || !response.data.id) {
      throw new Error('La estructura no fue creada correctamente. La respuesta no contiene un ID.');
    }

    console.log('Respuesta del servidor al crear la estructura:', response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Error 500: Error interno del servidor.', error.response.data);
    } else if (error.response && error.response.status === 401) {
      console.error('Error creando la estructura: No autorizado.');
    } else {
      console.error('Error creando la estructura:', error.response ? error.response.data : error.message);
    }
    throw error;
  }
};

// Función para eliminar una nota
export const deleteNote = async (noteId) => {
  try {
    const response = await api.delete(`/notes/${noteId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error('Error eliminando la nota: No autorizado.');
    } else {
      console.error('Error eliminando la nota:', error);
    }
    throw error;
  }
};

// Función para eliminar una estructura por su ID
export const deleteStructure = async (structureId) => {
  try {
    const response = await api.delete(`/structures/${structureId}`); // Cambia axios por api
    return response.data;
  } catch (error) {
    throw new Error('Error al eliminar la estructura');
  }
};

// Función para solicitar el restablecimiento de contraseña
export const forgotPassword = async (email) => {
  try {
    const response = await api.post('/password/email', { email });
    return response.data;
  } catch (error) {
    console.error('Error solicitando restablecimiento de contraseña:', error);
    throw error;
  }
};

// Función para restablecer la contraseña
export const resetPassword = async (data) => {
  try {
    const response = await api.post('/password/reset', data);
    return response.data;
  } catch (error) {
    console.error('Error restableciendo la contraseña:', error);
    throw error;
  }
};

// Función para verificar el email
export const verifyEmail = async (data) => {
  try {
    const response = await api.post('/email/verify', data);
    return response.data;
  } catch (error) {
    console.error('Error durante la verificación de email:', error);
    throw error;
  }
};

// Función para actualizar una estructura por su ID
export const updateStructure = async (structure, token) => {
  try {
    const response = await api.put(`/structures/${structure.id}`, structure, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.data || !response.data.id) {
      throw new Error('La estructura no fue actualizada correctamente. La respuesta no contiene un ID.');
    }

    console.log('Respuesta del servidor al actualizar la estructura:', response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error('Error 500: Error interno del servidor.', error.response.data);
    } else if (error.response && error.response.status === 401) {
      console.error('Error actualizando la estructura: No autorizado.');
    } else {
      console.error('Error actualizando la estructura:', error.response ? error.response.data : error.message);
    }
    throw error;
  }
};



export default api;
