import React, { useState } from 'react';
import { register } from '../services/api';
import { useNavigate, Link } from 'react-router-dom';
import './Register.css';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Attempting to register with:', {
      name,
      email,
      password,
      passwordConfirmation,
    });

    try {
      const response = await register(name, email, password, passwordConfirmation);
      console.log('Response from API:', response);

      // Mostrar mensaje de registro
      const message = response?.message || 'Registro exitoso. Por favor, verifica tu correo electrónico.';
      alert(message);

      // Redirigir al login después del registro
      navigate('/login');
    } catch (error) {
      console.error('Error al registrarse:', error);

      if (error.response) {
        console.error('Error response status:', error.response.status);
        console.error('Error response data:', error.response.data);
        alert(error.response.data.message || 'Error al registrarse. Por favor, intenta de nuevo.');
      } else if (error.request) {
        console.error('Error request:', error.request);
        alert('No se pudo conectar con el servidor.');
      } else {
        console.error('General error message:', error.message);
        alert('Ocurrió un error inesperado.');
      }
    }
  };

  return (
    <div className="register-container">
      <form onSubmit={handleSubmit} className="register-form">
        <h2>Registrarse</h2>
        <div className="form-group">
          <label>Nombre:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Confirmar Password:</label>
          <input
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="register-button">Registrarse</button>
        <p>¿Ya tienes una cuenta? <Link to="/login">Inicia Sesión</Link></p>
      </form>
    </div>
  );
};

export default Register;
