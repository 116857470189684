import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { createClient as createPexelsClient } from 'pexels';
import { createApi as createUnsplashApi } from 'unsplash-js';
import './AdvancedGenerator.css';
import { createSubnote } from './services/api';


const OPENAI_API_KEY = 'sk-proj-XKRex8fCQqVh0kOj6ONST3BlbkFJBoVvNt8wqPzMzQPbnezJ';
const PEXELS_API_KEY = 'biqKY8cKzZQZcANkk3MoR9Ft5TUCXLGVMDcOvOJWXyyAevkSvyWB1dMt';
const UNSPLASH_ACCESS_KEY = 'ufoE9if3wnET62ihy9cpt96W-z5oOlAxNM3EuSaMAKQ';

const pexelsClient = createPexelsClient(PEXELS_API_KEY);
const unsplash = createUnsplashApi({
  accessKey: UNSPLASH_ACCESS_KEY,
});
Modal.setAppElement('#root');

const AdvancedGenerator = ({ isDarkMode, notes, setNotes, selectedNote, setSelectedNote }) => {
  const location = useLocation();
  const [structure, setStructure] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [interlinkNotes, setInterlinkNotes] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [wordpressUrl, setWordpressUrl] = useState('');
  const [wordpressUser, setWordpressUser] = useState('');
  const [wordpressPassword, setWordpressPassword] = useState('');
  const [generatingImage, setGeneratingImage] = useState(false);
  const [generatedImageUrl, setGeneratedImageUrl] = useState('');
  const [customImageQuery, setCustomImageQuery] = useState('');
  const [generatedKeywords, setGeneratedKeywords] = useState('');

  const [title, setTitle] = useState('');
  const [meta_description, setMetaDescription] = useState(''); // Cambiado a meta_description
  const [tags, setTags] = useState('');
  const [keywords, setKeywords] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [content, setContent] = useState('');
  const [conclusion, setConclusion] = useState('');


  const [model, setModel] = useState('gpt-4o-2024-08-06');

  const maxLength = 4096;

  useEffect(() => {
    // Solo cargar estructura si viene desde el location o si está en sessionStorage
    if (location.state && location.state.structure) {
      const updatedStructure = { ...location.state.structure };
      if (typeof updatedStructure.tree === 'string') {
        updatedStructure.tree = JSON.parse(updatedStructure.tree);
      }
      setStructure(updatedStructure);
    } else {
      // Aquí eliminamos la carga automática desde sessionStorage para que no se cargue ninguna estructura
      console.log('No se ha recibido ninguna estructura para cargar en el AdvancedGenerator.');
    }
  }, [location.state]);



  const handleToneChange = (e, path) => {
    const newStructure = { ...structure };
    let node = newStructure.tree;
    path.forEach(index => {
      node = node.subtemas[index];
    });
    node.tone = e.target.value;
    setStructure(newStructure);
  };

  const handlePerspectiveChange = (e, path) => {
    const newStructure = { ...structure };
    let node = newStructure.tree;
    path.forEach(index => {
      node = node.subtemas[index];
    });
    node.perspective = e.target.value;
    setStructure(newStructure);
  };

  const handleInstructionsChange = (e) => {
    setAdditionalInstructions(e.target.value);
  };


  

  const renderSubtemas = (subtemas, path = []) => {
    if (!subtemas) return null;
    return subtemas.map((subtema, index) => (
      <React.Fragment key={`${path.join('-')}-${index}`}>
        <tr>
          <td>
            <input
              type="checkbox"
              checked={selectedNotes.includes(`${path.join('-')}-${index}`)}
              onChange={() => handleSelectNote(`${path.join('-')}-${index}`)}
            />
          </td>
          <td>
            {subtema.tema}
          </td>
          
          <td>
            <select
              value={subtema.tone || 'neutral'}
              onChange={(e) => handleToneChange(e, [...path, index])}
            >
              <option value="neutral">Neutral</option>
              <option value="amigable">Amigable</option>
              <option value="formal">Formal</option>
              <option value="emocional">Emocional</option>
              <option value="informal">Informal</option>
            </select>
          </td>
          <td>
            <select
              value={subtema.perspective || 'informativa'}
              onChange={(e) => handlePerspectiveChange(e, [...path, index])}
            >
              <option value="informativa">Informativa</option>
              <option value="persuasiva">Persuasiva</option>
              <option value="narrativa">Narrativa</option>
              <option value="descriptiva">Descriptiva</option>
              <option value="analítica">Analítica</option>
            </select>
          </td>
          <td>
          <button
  onClick={() => handleWrite(subtema, [...path, index])}
  className={`action-button ${isDarkMode ? 'dark' : ''}`}
  disabled={subtema.status === 'Escribiendo'}
>
  {subtema.status === 'Escribiendo' ? 'Escribiendo...' : 'Escribir'}
</button>




          </td>
        </tr>
        {renderSubtemas(subtema.subtemas, [...path, index])}
      </React.Fragment>
    ));
  };

  const handleSelectNote = (index) => {
    setSelectedNotes(prevSelectedNotes =>
      prevSelectedNotes.includes(index)
        ? prevSelectedNotes.filter(noteIndex => noteIndex !== index)
        : [...prevSelectedNotes, index]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedNotes([]);
    } else {
      const allIndexes = [];
      const collectIndexes = (subtemas, path = []) => {
        subtemas.forEach((subtema, index) => {
          allIndexes.push(`${path.join('-')}-${index}`);
          if (subtema.subtemas.length > 0) {
            collectIndexes(subtema.subtemas, [...path, index]);
          }
        });
      };
      collectIndexes(structure.tree.subtemas);
      setSelectedNotes(allIndexes);
    }
    setSelectAll(!selectAll);
  };



  const handleWrite = async (subtema, path) => {
    try {
        const updatedStructure = { ...structure };
        let node = updatedStructure.tree;

        // Recorrer la estructura para llegar al subtema correcto
        path.forEach(index => {
            node = node.subtemas[index];
        });
        node.status = 'Escribiendo';
        setStructure(updatedStructure);

      // Generar el prompt para la API de OpenAI
      const completePrompt = `
        Tienes una estructura general sobre el tema: "${structure.title}". 
        Dentro de esta estructura, uno de los subtemas es "${subtema.tema}". 
        Escribe un artículo detallado sobre "${subtema.tema}"siguiendo las instrucciones que te dejo aquí: 
        El artículo debe tener al menos 2000 o 3000 palabras e incluir una introducción, desarrollo de contenido en profundidad, y una conclusión.
        El artículo debe incluir:
        1. Un título optimizado para SEO (máximo 10 palabras, sin comillas).
        2. Una meta descripción (de MENOS de 255 caracteres).
        3. Una introducción completa.
        4. Etiquetas de WordPress (al menos 5 etiquetas, separadas por comas, sin comillas).
        5. Al menos cinco palabras clave (sin comillas).
        6. Introduce "Desarrollo:" como un marcador después de la introducción, antes de empezar el desarrollo de los temas. **Este texto es solo un marcador y no debe mostrarse ni guardarse en el contenido final**.
        7. Varios temas a desarrollar, cada tema que tenga un título, (Nunca mencionar "Tema 1:" "Tema 2:"... es decir, por favor, que siempre cada tema tenga un título), por ejemplo <h2>[Título del Tema 1]<h2> 
        8. Contenido detallado bien largo para cada tema, si es necesario que cada tema contenga subtemas, cada subtema que tenga un título, (Nunca mencionar "Subtema 1:" "Subtema 2:"... es decir, por favor, que siempre cada subtema tenga un título), en h3 o h4, y que esos subtemas sean detallados también.
        9. Una conclusión.
        10. No uses negritas, asteriscos, hashes, ni otros caracteres especiales para el formato.
        11. El artículo debe tener al menos 2000 palabras y ser coherente y conciso.

        A continuación, te proporciono la estructura completa:
        Estructura general:
        Tema principal: "${structure.title}"
        Subtemas: ${structure.tree.subtemas.map(s => s.tema).join(', ')}
      
        Ahora, escribe un artículo detallado sobre el subtema "${subtema.tema}", respetando la estructura de un artículo SEO. 


        Estructura del artículo:
        Recordá no escribir "Tema 2, Tema 2, Tema 3"... en su lugar, utiliza los títulos y desarrollo de cada tema.
        Meta descripción: [Meta descripción aquí]
        Introducción: [Introducción aquí]
        Etiquetas de WordPress: [Etiquetas aquí]
        Palabras clave: [Palabras clave aquí]
        Título: [Título aquí]
        Desarrollo:
        <h2>[Título del Tema 1]<h2>
        <p>[Contenido del Tema 1]<p>
        <h2>[Título del Tema 2]<h2>
        <p>[Contenido del Tema 2]<p>
        <h2>[Título del Tema 3]<h2>
        <p>[Contenido del Tema 3]<p>
        <h2>[Título del Tema 4]<h2>
        <p>[Contenido del Tema 4]<p>
        <h2>[Título del Tema 5]<h2>
        <p>[Contenido del Tema 5]<p>
        Conclusión: [Conclusión aquí]

        Asegúrate de que cada tema esté bien desarrollado y el contenido sea coherente y conciso.

        Ejemplo de artículo:
        
        Título: Importancia y Aplicaciones de las Pruebas en Diversos Campos

        Meta descripción: Explora la importancia y diversas aplicaciones de las pruebas en la ciencia, tecnología, educación y más. Descubre cómo las pruebas mejoran la calidad y precisión en distintos campos.

        Introducción:
        Las pruebas son una parte fundamental de numerosos campos y disciplinas, desde la ciencia y la tecnología hasta la educación y la medicina. A través de las pruebas, es posible validar teorías, asegurar la calidad de productos, evaluar el conocimiento y garantizar la seguridad. En este artículo, exploraremos en detalle la importancia y las aplicaciones de las pruebas en diversos campos, destacando cómo estas contribuyen al avance y la mejora continua.

        Etiquetas de WordPress: pruebas, calidad, ciencia, tecnología, educación, medicina.

        Palabras clave: pruebas, validación, calidad, evaluación, seguridad.

        Pruebas en la Ciencia: Validación y Descubrimiento
        Las pruebas en la ciencia son esenciales para la validación de teorías y descubrimientos. A través de experimentos y ensayos, los científicos pueden confirmar o refutar hipótesis, lo que les permite avanzar en el conocimiento y la comprensión de diversos fenómenos naturales. Un ejemplo clásico de la importancia de las pruebas en la ciencia es el método científico utilizado por los investigadores para descubrir nuevos medicamentos. Antes de que un medicamento sea aprobado para su uso en humanos, debe pasar por una serie de pruebas preclínicas y clínicas que evalúan su seguridad y eficacia. 

        Estas pruebas son esenciales no solo en medicina, sino también en física y química. Los experimentos en física de partículas, por ejemplo, han permitido a los científicos descubrir nuevas partículas y entender mejor la estructura fundamental del universo. Sin estas rigurosas pruebas, muchos avances científicos no serían posibles.

        Pruebas en la Tecnología: Aseguramiento de la Calidad
        En tecnología, las pruebas garantizan la calidad y el correcto funcionamiento de productos y sistemas. En el desarrollo de software, las pruebas unitarias, de integración y de aceptación son cruciales para identificar y corregir errores antes del lanzamiento al mercado. 

        En la fabricación de dispositivos electrónicos, se realizan pruebas exhaustivas en cada etapa del proceso de producción para asegurarse de que los dispositivos cumplan con las especificaciones y no presenten defectos. Estas pruebas pueden incluir pruebas de funcionamiento, de resistencia y de seguridad eléctrica.

        En el ámbito de las telecomunicaciones, las pruebas de red aseguran la fiabilidad y el rendimiento, identificando problemas antes de que afecten a los usuarios finales. Los ingenieros realizan pruebas de carga y estrés para garantizar que las redes de comunicación funcionen de manera óptima.

        Pruebas en la Educación: Evaluación del Conocimiento
        En educación, las pruebas son herramientas esenciales para evaluar el conocimiento y el progreso de los estudiantes. Los exámenes y evaluaciones permiten a los educadores medir el rendimiento académico, identificar áreas de mejora y ajustar su enseñanza en consecuencia. Las pruebas estandarizadas son comunes en muchos sistemas educativos y permiten comparar el rendimiento de los estudiantes con un estándar establecido.

        Las evaluaciones formativas, como cuestionarios y proyectos, proporcionan una visión más completa del conocimiento y habilidades de los estudiantes, permitiendo a los educadores ofrecer retroalimentación inmediata y personalizada. En la educación técnica y profesional, las pruebas de habilidades prácticas son cruciales para evaluar la competencia en áreas como la carpintería, la enfermería y la ingeniería.

        Pruebas en la Medicina: Seguridad y Eficacia
        En medicina, las pruebas aseguran la seguridad y eficacia de tratamientos y procedimientos. Los ensayos clínicos evalúan nuevos medicamentos en varias fases, comenzando con pruebas en un pequeño grupo de voluntarios y expandiéndose a estudios más grandes. Los resultados determinan si un medicamento es seguro y efectivo para su uso en la práctica clínica.

        Las pruebas de diagnóstico permiten a los médicos diagnosticar enfermedades y condiciones médicas, facilitando tratamientos adecuados y oportunos. Estas pruebas incluyen análisis de sangre, biopsias, resonancias magnéticas y tomografías computarizadas. Además, los dispositivos médicos, como marcapasos o prótesis, deben pasar por rigurosas pruebas de funcionamiento y seguridad antes de su aprobación para uso clínico.

        Pruebas en la Ingeniería: Seguridad y Fiabilidad
        En ingeniería, las pruebas garantizan la seguridad y fiabilidad de estructuras y sistemas. En la ingeniería civil, las pruebas de materiales evalúan la resistencia y durabilidad, asegurando que los materiales de construcción cumplan con los estándares de seguridad. 

        En la ingeniería aeroespacial, las pruebas de sistemas aseguran la fiabilidad y seguridad de las aeronaves. Los ingenieros realizan pruebas de vuelo y simulaciones para identificar y corregir problemas antes de que las aeronaves sean utilizadas en operaciones comerciales. 

        Las pruebas también son cruciales en la ingeniería ferroviaria, donde se evalúa la integridad estructural de los trenes, la señalización y los sistemas de control para garantizar la seguridad de los pasajeros.

        Conclusión:
        Las pruebas son esenciales en numerosos campos, desde la ciencia y la tecnología hasta la educación y la medicina. Validan teorías, aseguran la calidad de productos, evalúan el conocimiento y garantizan la seguridad, contribuyendo al avance y la mejora continua en cada disciplina. Son una parte fundamental del progreso y la innovación en nuestra sociedad, permitiendo a los profesionales ofrecer productos y servicios de alta calidad.
      `;


      console.log('Prompt enviado a la API:', completePrompt);

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4o-2024-08-06',
          messages: [{ role: 'user', content: completePrompt }],
          max_tokens: maxLength,
          temperature: 0.5,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error en la respuesta de la API:', errorData);
        throw new Error(errorData.error.message || 'Error al obtener la respuesta de la API');
      }

      const data = await response.json();
      const responseText = data.choices[0].message.content;
      console.log('Respuesta de la API:', responseText);

      const titleMatch = responseText.match(/Título:\s*(.*)/i);
      const metaDescriptionMatch = responseText.match(/Meta descripción:\s*(.*)/i);
      const introductionMatch = responseText.match(/Introducción:\s*([\s\S]*?)\n(Etiquetas de WordPress|Palabras clave):/i);
      const tagsMatch = responseText.match(/Etiquetas de WordPress:\s*(.*)/i);
      const keywordsMatch = responseText.match(/Palabras clave:\s*(.*)/i);
  // Usa "Desarrollo:" como marcador para capturar el contenido
  const contentMatch = responseText.match(/Desarrollo:\s*([\s\S]*?)\nConclusión:/i);
      const conclusionMatch = responseText.match(/Conclusión:\s*(.*)/i);

      const generatedTitle = titleMatch ? titleMatch[1].trim() : 'Sin título';
      const generatedMetaDescription = metaDescriptionMatch ? metaDescriptionMatch[1].trim() : 'Sin descripción';
      const generatedIntroduction = introductionMatch ? introductionMatch[1].trim() : 'Sin introducción';
      const generatedTags = tagsMatch ? tagsMatch[1].trim() : 'Sin etiquetas';
      const generatedKeywords = keywordsMatch ? keywordsMatch[1].trim() : '';
  // Eliminar el marcador "Desarrollo:" del contenido
  const generatedContent = contentMatch ? contentMatch[1].trim().replace(/^Desarrollo:\s*/, '') : '';
      const generatedConclusion = conclusionMatch ? conclusionMatch[1].trim() : 'Sin conclusión';


      console.log('Datos extraídos de la respuesta:', {
        generatedTitle,
        generatedMetaDescription,
        generatedIntroduction,
        generatedTags,
        generatedKeywords,
        generatedContent,
        generatedConclusion
      });

      setTitle(generatedTitle);
      setMetaDescription(generatedMetaDescription);
      setIntroduction(generatedIntroduction);
      setTags(generatedTags);
      setKeywords(generatedKeywords);
      setContent(generatedContent);
      setConclusion(generatedConclusion);

  
      // Guardar los valores obtenidos
      const newSubnote = {
        subnote_id: Date.now(),
        title: generatedTitle,
        meta_description: generatedMetaDescription, // Cambiado a meta_description
        introduction: generatedIntroduction,
        tags: generatedTags,
        keywords: generatedKeywords,
        content: generatedContent,
        status: 'Finalizado',
        conclusion: generatedConclusion,
        imageUrl: generatedImageUrl,
        generatorType: 'advanced',
        perspective: subtema.perspective,
        model: subtema.model,
      };
  

      // Llamar a la función para guardar el subtema en la base de datos
      await saveSubnote(newSubnote);
  
      // Actualizar las notas en el estado
      setNotes((prevNotes) => {
        const updatedNotes = [...prevNotes, newSubnote];
        localStorage.setItem('notes', JSON.stringify(updatedNotes));
        sessionStorage.setItem('notes', JSON.stringify(updatedNotes));
        return updatedNotes;
      });
  
      setStructure(updatedStructure);
      sessionStorage.setItem('structure', JSON.stringify(updatedStructure));
  
    } catch (err) {
      setError(err.message || 'Error al generar el contenido');
      console.error('Error:', err);
    }
  };

// Función para guardar el subtema en el backend (Laravel)
// Función para guardar el subtema en el backend (Laravel)
const saveSubnote = async (subnote) => {
  try {
    const userId = localStorage.getItem('user_id');
    if (!userId) {
      console.error('User ID no encontrado en localStorage.');
      return;
    }

    const subnoteData = {
      subnote_id: subnote.subnote_id,
      user_id: userId,
      structure_id: structure.id,
      title: subnote.title,
      content: subnote.content,  // Asegurarnos de guardar el contenido
      meta_description: subnote.meta_description || '',
      tags: subnote.tags || '',
      keywords: subnote.keywords || '',
      introduction: subnote.introduction || '',
      conclusion: subnote.conclusion || '',
      image_url: subnote.image_url || '',
    };

    const response = await createSubnote(subnoteData);

    const storedNotes = JSON.parse(localStorage.getItem('subnotes')) || [];
    const updatedNotes = [...storedNotes, response];
    localStorage.setItem('subnotes', JSON.stringify(updatedNotes));
    sessionStorage.setItem('subnotes', JSON.stringify(updatedNotes));

  } catch (error) {
    console.error('Error al guardar el subtema:', error.message);
  }
};


// Función para manejar la escritura de todas las subnotas
const handleWriteAll = () => {
    const subtemasList = [];
    const flattenSubtemas = (subtemas, path = []) => {
        subtemas.forEach((subtema, index) => {
            if (subtema.status !== 'Finalizado') {
                subtemasList.push({ subtema, path: [...path, index] });
            }
            if (subtema.subtemas.length > 0) {
                flattenSubtemas(subtema.subtemas, [...path, index]);
            }
        });
    };
    flattenSubtemas(structure.tree.subtemas);
    subtemasList.forEach(({ subtema, path }) => handleWrite(subtema, path));
};

  return (
    <div className={`generator-container ${isDarkMode ? 'dark' : ''}`}>
      <header className={`App-header ${isDarkMode ? 'dark' : ''}`}>
        <h1 className='generador-avanzado'>Generador Avanzado</h1>
        {structure && (
     <div className={`advanced-generator-form ${isDarkMode ? 'dark' : ''}`}>
            <div className="select-all-container">
            </div>
            <table className={`subtemas-table ${isDarkMode ? 'dark' : ''}`}>
              <thead>
                <tr>
                  <th>Seleccionar</th>
                  <th>Título</th>
                  <th>Tono de Voz</th>
                  <th>Perspectiva</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {renderSubtemas(structure.tree.subtemas)}
              </tbody>
            </table>
            <div className={`input-group ${isDarkMode ? 'dark' : ''}`}>
              <label>Instrucciones Adicionales:</label>
              <textarea
                value={additionalInstructions}
                onChange={handleInstructionsChange}
                placeholder="Escribe instrucciones adicionales para el generador"
              />
            </div>
            <div className="buttons-container">
            <button onClick={handleSelectAll} className={`select-all-button ${isDarkMode ? 'dark' : ''}`}>
                {selectAll ? 'Desmarcar Todas' : 'Seleccionar Todas'}
              </button>
              <button onClick={handleWriteAll} className={`start-writing-button ${isDarkMode ? 'dark' : ''}`}>
                Escribir Todos
              </button>
            </div>
            {loading && (
              <div className="loading-bar" style={{ marginTop: '20px' }}>
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${progress}%` }}></div>
                </div>
                <p>{Math.round(progress)}% Generando contenido...</p>
              </div>
            )}
            {error && <p className="error">{error}</p>}
          </div>
        )}
      </header>

    </div>
  );
};

export const formatContent = (text) => {
  if (!text) return null;
  const paragraphs = text.split('\n').map((paragraph, index) => (
    <div key={index}>
      <p>{paragraph}</p>
    </div>
  ));
  return paragraphs;
};

export default AdvancedGenerator;

