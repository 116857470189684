import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';
import api, { createNote, deleteNote } from './services/api';
import './App.css';
import { createClient as createPexelsClient } from 'pexels';
import { createApi as createUnsplashApi } from 'unsplash-js';

const PEXELS_API_KEY = 'biqKY8cKzZQZcANkk3MoR9Ft5TUCXLGVMDcOvOJWXyyAevkSvyWB1dMt';
const UNSPLASH_ACCESS_KEY = 'ufoE9if3wnET62ihy9cpt96W-z5oOlAxNM3EuSaMAKQ';

const pexelsClient = createPexelsClient(PEXELS_API_KEY);
const unsplash = createUnsplashApi({
  accessKey: UNSPLASH_ACCESS_KEY,
});
const OPENAI_API_KEY = 'sk-proj-XKRex8fCQqVh0kOj6ONST3BlbkFJBoVvNt8wqPzMzQPbnezJ';

const SimpleGenerator = ({ isDarkMode, notes = [], setNotes, selectedNote, setSelectedNote }) => {
  const { noteId } = useParams();
  const location = useLocation();
  const [prompt, setPrompt] = useState('');
  const [model, setModel] = useState('gpt-4o-2024-08-06');
  const [title, setTitle] = useState('');
  const [meta_description, setMetaDescription] = useState(''); // Cambiado a meta_description
  const [tags, setTags] = useState('');
  const [keywords, setKeywords] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [content, setContent] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [wordpressUrl, setWordpressUrl] = useState('');
  const [wordpressUsername, setWordpressUsername] = useState('');
  const [wordpressAppPassword, setWordpressAppPassword] = useState('');
  const [generatingImage, setGeneratingImage] = useState(false);
  const [generatedImageUrl, setGeneratedImageUrl] = useState('');
  const [showWordpressFields, setShowWordpressFields] = useState(false);
  const [customImageQuery, setCustomImageQuery] = useState('');  // Para consultas de imagen personalizadas
  const fullImageUrl = generatedImageUrl.startsWith('http')
  ? generatedImageUrl
  : `https://databasecgpt.mktmarketingdigital.com/api/storage/images/${generatedImageUrl}`;




  const maxLength = 4096;
  const totalSteps = 10;

  const [isPublishing, setIsPublishing] = useState(false); // Nuevo estado para rastrear la publicación

  const updateProgress = (step) => {
    setProgress((step / totalSteps) * 100);
  };

   // Función para manejar la eliminación de la nota
   const handleDeleteNote = async () => {
    const note = notes[selectedNote];
    if (!note || !note.id) {
      console.error('ID de la nota no disponible. No se puede eliminar la nota.');
      setError('ID de la nota no disponible. No se puede eliminar la nota.');
      return;
    }

    try {
      await deleteNote(note.id);  // Llamada a la API para eliminar la nota
      setNotes(prevNotes => prevNotes.filter(n => n.id !== note.id));  // Actualiza el estado de las notas
      setSelectedNote(null);  // Des-selecciona la nota
      console.log('Nota eliminada con éxito.');
    } catch (error) {
      console.error('Error al eliminar la nota:', error);
      setError('Error al eliminar la nota.');
    }
  };

  useEffect(() => {
    if (noteId) {
      const noteIndex = notes.findIndex((note) => note.id === parseInt(noteId));
      if (noteIndex !== -1) {
        setSelectedNote(noteIndex);
      }
    }
  }, [noteId, notes, setSelectedNote]);

  useEffect(() => {
    if (location.state && location.state.prompt) {
      setPrompt(location.state.prompt);
    }
  }, [location.state]);

  const generateArticle = async () => {
    setLoading(true);
    setError('');
    updateProgress(0);

    try {
      const userId = localStorage.getItem('user_id');
if (!userId) {
  throw new Error('User ID no encontrado en localStorage.');
}
      console.log('User ID almacenado:', userId);

      const completePrompt = `
        Crea un artículo completo sobre el siguiente tema: "${prompt}".
        El artículo debe incluir:
        1. Un título optimizado para SEO (máximo 10 palabras, sin comillas).
        2. Una meta descripción (de MENOS de 255 caracteres).
        3. Una introducción completa.
        4. Etiquetas de WordPress (al menos 5 etiquetas, separadas por comas, sin comillas).
        5. Al menos cinco palabras clave (sin comillas).
        6. Varios temas a desarrollar, cada tema que tenga un título, (Nunca mencionar "Tema 1:" "Tema 2:"... es decir, por favor, que siempre cada tema tenga un título), por ejemplo <h2>[Título del Tema 1]<h2> 
        7. Contenido detallado bien largo para cada tema, si es necesario que cada tema contenga subtemas, cada subtema que tenga un título, (Nunca mencionar "Subtema 1:" "Subtema 2:"... es decir, por favor, que siempre cada subtema tenga un título), en h3 o h4, y que esos subtemas sean detallados también.
        8. Una conclusión.
        9. No uses negritas, asteriscos, hashes, ni otros caracteres especiales para el formato.
        10. El artículo debe tener al menos 2000 palabras y ser coherente y conciso.

        Estructura del artículo:
        Recordá no escribir "Tema 2, Tema 2, Tema 3"... en su lugar, utiliza los títulos y desarrollo de cada tema.
        Meta descripción: [Meta descripción aquí]
        Introducción: [Introducción aquí]
        Etiquetas de WordPress: [Etiquetas aquí]
        Palabras clave: [Palabras clave aquí]
        Título: [Título aquí]
        Desarrollo:
        <h2>[Título del Tema 1]<h2>
        <p>[Contenido del Tema 1]<p>
        <h2>[Título del Tema 2]<h2>
        <p>[Contenido del Tema 2]<p>
        <h2>[Título del Tema 3]<h2>
        <p>[Contenido del Tema 3]<p>
        <h2>[Título del Tema 4]<h2>
        <p>[Contenido del Tema 4]<p>
        <h2>[Título del Tema 5]<h2>
        <p>[Contenido del Tema 5]<p>
        Conclusión: [Conclusión aquí]

        Asegúrate de que cada tema esté bien desarrollado y el contenido sea coherente y conciso.

        Ejemplo de artículo:
        
        Título: Importancia y Aplicaciones de las Pruebas en Diversos Campos

        Meta descripción: Explora la importancia y diversas aplicaciones de las pruebas en la ciencia, tecnología, educación y más. Descubre cómo las pruebas mejoran la calidad y precisión en distintos campos.

        Introducción:
        Las pruebas son una parte fundamental de numerosos campos y disciplinas, desde la ciencia y la tecnología hasta la educación y la medicina. A través de las pruebas, es posible validar teorías, asegurar la calidad de productos, evaluar el conocimiento y garantizar la seguridad. En este artículo, exploraremos en detalle la importancia y las aplicaciones de las pruebas en diversos campos, destacando cómo estas contribuyen al avance y la mejora continua.

        Etiquetas de WordPress: pruebas, calidad, ciencia, tecnología, educación, medicina.

        Palabras clave: pruebas, validación, calidad, evaluación, seguridad.

        Pruebas en la Ciencia: Validación y Descubrimiento
        Las pruebas en la ciencia son esenciales para la validación de teorías y descubrimientos. A través de experimentos y ensayos, los científicos pueden confirmar o refutar hipótesis, lo que les permite avanzar en el conocimiento y la comprensión de diversos fenómenos naturales. Un ejemplo clásico de la importancia de las pruebas en la ciencia es el método científico utilizado por los investigadores para descubrir nuevos medicamentos. Antes de que un medicamento sea aprobado para su uso en humanos, debe pasar por una serie de pruebas preclínicas y clínicas que evalúan su seguridad y eficacia. 

        Estas pruebas son esenciales no solo en medicina, sino también en física y química. Los experimentos en física de partículas, por ejemplo, han permitido a los científicos descubrir nuevas partículas y entender mejor la estructura fundamental del universo. Sin estas rigurosas pruebas, muchos avances científicos no serían posibles.

        Pruebas en la Tecnología: Aseguramiento de la Calidad
        En tecnología, las pruebas garantizan la calidad y el correcto funcionamiento de productos y sistemas. En el desarrollo de software, las pruebas unitarias, de integración y de aceptación son cruciales para identificar y corregir errores antes del lanzamiento al mercado. 

        En la fabricación de dispositivos electrónicos, se realizan pruebas exhaustivas en cada etapa del proceso de producción para asegurarse de que los dispositivos cumplan con las especificaciones y no presenten defectos. Estas pruebas pueden incluir pruebas de funcionamiento, de resistencia y de seguridad eléctrica.

        En el ámbito de las telecomunicaciones, las pruebas de red aseguran la fiabilidad y el rendimiento, identificando problemas antes de que afecten a los usuarios finales. Los ingenieros realizan pruebas de carga y estrés para garantizar que las redes de comunicación funcionen de manera óptima.

        Pruebas en la Educación: Evaluación del Conocimiento
        En educación, las pruebas son herramientas esenciales para evaluar el conocimiento y el progreso de los estudiantes. Los exámenes y evaluaciones permiten a los educadores medir el rendimiento académico, identificar áreas de mejora y ajustar su enseñanza en consecuencia. Las pruebas estandarizadas son comunes en muchos sistemas educativos y permiten comparar el rendimiento de los estudiantes con un estándar establecido.

        Las evaluaciones formativas, como cuestionarios y proyectos, proporcionan una visión más completa del conocimiento y habilidades de los estudiantes, permitiendo a los educadores ofrecer retroalimentación inmediata y personalizada. En la educación técnica y profesional, las pruebas de habilidades prácticas son cruciales para evaluar la competencia en áreas como la carpintería, la enfermería y la ingeniería.

        Pruebas en la Medicina: Seguridad y Eficacia
        En medicina, las pruebas aseguran la seguridad y eficacia de tratamientos y procedimientos. Los ensayos clínicos evalúan nuevos medicamentos en varias fases, comenzando con pruebas en un pequeño grupo de voluntarios y expandiéndose a estudios más grandes. Los resultados determinan si un medicamento es seguro y efectivo para su uso en la práctica clínica.

        Las pruebas de diagnóstico permiten a los médicos diagnosticar enfermedades y condiciones médicas, facilitando tratamientos adecuados y oportunos. Estas pruebas incluyen análisis de sangre, biopsias, resonancias magnéticas y tomografías computarizadas. Además, los dispositivos médicos, como marcapasos o prótesis, deben pasar por rigurosas pruebas de funcionamiento y seguridad antes de su aprobación para uso clínico.

        Pruebas en la Ingeniería: Seguridad y Fiabilidad
        En ingeniería, las pruebas garantizan la seguridad y fiabilidad de estructuras y sistemas. En la ingeniería civil, las pruebas de materiales evalúan la resistencia y durabilidad, asegurando que los materiales de construcción cumplan con los estándares de seguridad. 

        En la ingeniería aeroespacial, las pruebas de sistemas aseguran la fiabilidad y seguridad de las aeronaves. Los ingenieros realizan pruebas de vuelo y simulaciones para identificar y corregir problemas antes de que las aeronaves sean utilizadas en operaciones comerciales. 

        Las pruebas también son cruciales en la ingeniería ferroviaria, donde se evalúa la integridad estructural de los trenes, la señalización y los sistemas de control para garantizar la seguridad de los pasajeros.

        Conclusión:
        Las pruebas son esenciales en numerosos campos, desde la ciencia y la tecnología hasta la educación y la medicina. Validan teorías, aseguran la calidad de productos, evalúan el conocimiento y garantizan la seguridad, contribuyendo al avance y la mejora continua en cada disciplina. Son una parte fundamental del progreso y la innovación en nuestra sociedad, permitiendo a los profesionales ofrecer productos y servicios de alta calidad.
      `;

      console.log('Prompt enviado a la API:', completePrompt);

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4o-2024-08-06',
          messages: [{ role: 'user', content: completePrompt }],
          max_tokens: maxLength,
          temperature: 0.5,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error en la respuesta de la API:', errorData);
        throw new Error(errorData.error.message || 'Error al obtener la respuesta de la API');
      }

      const data = await response.json();
      const responseText = data.choices[0].message.content;
      console.log('Respuesta de la API:', responseText);

      const titleMatch = responseText.match(/Título:\s*(.*)/i);
      const metaDescriptionMatch = responseText.match(/Meta descripción:\s*(.*)/i);
      const introductionMatch = responseText.match(/Introducción:\s*([\s\S]*?)\n(Etiquetas de WordPress|Palabras clave):/i);
      const tagsMatch = responseText.match(/Etiquetas de WordPress:\s*(.*)/i);
      const keywordsMatch = responseText.match(/Palabras clave:\s*(.*)/i);
      const contentMatch = responseText.match(/Desarrollo:\s*([\S\s]*?)\nConclusión:/i);
      const conclusionMatch = responseText.match(/Conclusión:\s*(.*)/i);

      const generatedTitle = titleMatch ? titleMatch[1].trim() : 'Sin título';
      const generatedMetaDescription = metaDescriptionMatch ? metaDescriptionMatch[1].trim() : 'Sin descripción';
      const generatedIntroduction = introductionMatch ? introductionMatch[1].trim() : 'Sin introducción';
      const generatedTags = tagsMatch ? tagsMatch[1].trim() : 'Sin etiquetas';
      const generatedKeywords = keywordsMatch ? keywordsMatch[1].trim() : '';
      const generatedContent = contentMatch ? contentMatch[1].trim() : '';
      const generatedConclusion = conclusionMatch ? conclusionMatch[1].trim() : 'Sin conclusión';

      console.log('Datos extraídos de la respuesta:', {
        generatedTitle,
        generatedMetaDescription,
        generatedIntroduction,
        generatedTags,
        generatedKeywords,
        generatedContent,
        generatedConclusion
      });

      setTitle(generatedTitle);
      setMetaDescription(generatedMetaDescription);
      setIntroduction(generatedIntroduction);
      setTags(generatedTags);
      setKeywords(generatedKeywords);
      setContent(generatedContent);
      setConclusion(generatedConclusion);

      const newNote = {
        title: generatedTitle,
        meta_description: generatedMetaDescription, // Cambiado a meta_description
        introduction: generatedIntroduction,
        tags: generatedTags,
        keywords: generatedKeywords,
        content: generatedContent,
        generatorType: 'simple', // Añadir esta línea
        conclusion: generatedConclusion,
        imageUrl: generatedImageUrl,
        user_id: userId,
      };

      const token = sessionStorage.getItem('token'); // Cambiado a sessionStorage
      const createdNote = await createNote({
        ...newNote,
        user_id: userId,
      }, token);

      setNotes((prevNotes) => {
        const updatedNotes = [...prevNotes, { ...newNote, id: createdNote.id }]; // Asigna el ID
        sessionStorage.setItem('notes', JSON.stringify(updatedNotes)); // Cambiado a sessionStorage
        setSelectedNote(updatedNotes.length - 1);
        return updatedNotes;
      });

      updateProgress(totalSteps);
    } catch (err) {
      setError(err.message || 'Error al generar el artículo');
      console.error('Error en el proceso de generación del artículo:', err);
    } finally {
      setLoading(false);
    }
  };

  const formatContent = (text) => {
    if (!text) return null;
    const paragraphs = text.split('\n').map((paragraph, index) => (
      <div key={index}>
        <p>{paragraph}</p>
      </div>
    ));
    return paragraphs;
  };


  useEffect(() => {
    if (selectedNote) {
      setGeneratedImageUrl(selectedNote.image_url);
    }
  }, [selectedNote]);
  

  const saveChanges = async () => {
    const token = localStorage.getItem('token'); // Obtener el token del localStorage
    const userId = localStorage.getItem('user_id'); // Obtener el user_id del localStorage
  
    if (!userId) {
      console.error('User ID no encontrado.');
      setError('User ID no encontrado. Asegúrate de haber iniciado sesión correctamente.');
      return;
    }
  
    const note = notes[selectedNote];
    if (!note || !note.id) {
      console.error('ID de la nota no disponible. No se puede actualizar la nota.');
      setError('ID de la nota no disponible. No se puede actualizar la nota.');
      return;
    }
  
    const updatedNote = {
      ...note, // Mantiene todos los datos actuales de la nota, incluido el user_id
      title,
      meta_description, // Asegúrate de que esté actualizado correctamente
      introduction,
      tags,
      keywords,
      content,
      conclusion,
      image_url: generatedImageUrl,
      user_id: userId, // Asegurarse de no perder el user_id al actualizar
    };
  
    try {
      console.log('Actualizando nota con ID:', note.id, 'y datos:', updatedNote);
      const response = await api.put(`/notes/${note.id}`, updatedNote, {
        headers: {
          Authorization: `Bearer ${token}`, // Asegúrate de pasar el token en la solicitud
        },
      });
  
      if (response.status === 200) {
        setNotes((prevNotes) => {
          const updatedNotes = prevNotes.map((n) =>
            n.id === note.id ? { ...n, ...updatedNote } : n
          );
          localStorage.setItem('notes', JSON.stringify(updatedNotes)); // Actualizar el localStorage con las notas actualizadas
          return updatedNotes;
        });
        console.log('Nota actualizada con éxito.');
        setIsEditing(false);
      } else {
        setError('Error al actualizar la nota.');
        console.error('Error al actualizar la nota:', response.statusText);
      }
    } catch (error) {
      setError('Error al actualizar la nota.');
      console.error('Error en la actualización de la nota:', error);
    }
  };
  
  const publishToWordpress = async () => {
    setIsPublishing(true); // Activar el estado de publicando
  
    const createOrGetTags = async (tagNames) => {
      const tagIds = [];
      for (const tagName of tagNames) {
        try {
          let response = await fetch(`${wordpressUrl}/wp-json/wp/v2/tags?search=${encodeURIComponent(tagName)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
            },
          });
  
          let tags = await response.json();
  
          if (tags.length > 0) {
            tagIds.push(tags[0].id);
          } else {
            response = await fetch(`${wordpressUrl}/wp-json/wp/v2/tags`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
              },
              body: JSON.stringify({ name: tagName }),
            });
  
            const newTag = await response.json();
            tagIds.push(newTag.id);
          }
        } catch (err) {
          console.error(`Error al crear o obtener la etiqueta "${tagName}":`, err);
          throw err;
        }
      }
      return tagIds;
    };
  
    const uploadFeaturedImage = async (imageUrl) => {
      if (!imageUrl) {
        console.log('No se encontró imagen generada, no se subirá ninguna imagen destacada.');
        return null; // No hay imagen, retornar null
      }
  
      try {
        console.log(`Subiendo imagen destacada desde URL: ${imageUrl}`);
        const response = await fetch(`${wordpressUrl}/wp-json/wp/v2/media`, {
          method: 'POST',
          headers: {
            'Content-Disposition': `attachment; filename=${title.replace(/\s+/g, '_')}.jpg`,
            'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
          },
          body: await fetch(imageUrl).then(res => res.blob())
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error al subir la imagen a WordPress');
        }
  
        const mediaData = await response.json();
        console.log(`Imagen destacada subida con éxito. ID: ${mediaData.id}`);
        return mediaData.id;
      } catch (error) {
        console.error("Error al subir la imagen destacada:", error);
        throw error;
      }
    };
  
    try {
      // Si no hay una imagen generada, el featuredMediaId será null
      const featuredMediaId = generatedImageUrl ? await uploadFeaturedImage(generatedImageUrl) : null;
  
      const tagNames = tags.split(',').map(tag => tag.trim());
      const tagIds = await createOrGetTags(tagNames);
    
      // Publicar el artículo en WordPress con metadatos tanto para Rank Math como para Yoast
      const post = {
        title: title,
        content:`${introduction}${content}${conclusion}`,
        status: 'draft', // Cambiar a 'publish' si quieres publicarlo directamente
        tags: tagIds,
        featured_media: featuredMediaId, // Solo agregar si hay imagen
        meta: {
          rank_math_description: meta_description,
          rank_math_focus_keyword: keywords,
          yoast_wpseo_metadesc: meta_description,
          yoast_wpseo_focuskw: keywords,
        }
      };
  
      const response = await fetch(`${wordpressUrl}/wp-json/wp/v2/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
        },
        body: JSON.stringify(post),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error en la respuesta de WordPress:', errorData);
        throw new Error(errorData.message || 'Error al publicar en WordPress');
      }
  
      const data = await response.json();
      console.log('Artículo subido como borrador en WordPress:', data);
      alert('Artículo subido como borrador en WordPress exitosamente');
    } catch (err) {
      setError(err.message || 'Error al subir como borrador en WordPress');
      console.error('Error en el proceso de publicación en WordPress:', err);
    } finally {
      setIsPublishing(false);
    }
  };
  

  const saveImagePermanently = async (imageUrl) => {
    try {
      const response = await api.post('/save-image', { imageUrl }); // Asegúrate de que estás usando la ruta correcta aquí.
      return response.data.savedImageUrl; // Asegúrate de que el backend está devolviendo la URL correcta.
    } catch (error) {
      console.error("Error al guardar la imagen permanentemente:", error);
    }
  };
  

const handleGenerateImage = async (useCustomQuery = false, source = 'pexels') => {
  setGeneratingImage(true);
  setGeneratedImageUrl('');
  setError('');

  let query = '';
  let randomPage = Math.floor(Math.random() * 10) + 1; // Generar un número de página aleatorio entre 1 y 10

  if (useCustomQuery && customImageQuery.trim() !== '') {
    query = customImageQuery.trim();
  } else {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          messages: [{
            role: 'user',
            content: `Lee el siguiente artículo y genera una palabra clave o dos palabras 
            (en lo posible siempre una palabra sola, solo utiliza más de una palabra si es demasiado necesario) 
            que me sirvan como query para buscar una imagen adecuada para el articulo, si es necesario contextualiza, 
            o utiliza otras palabras que no estén en el artículo, que sirvan para buscar una imagen referida al artículo, 
            si se refiere a locaciones intentá ser lo más exacto posible para poder encontrar imágenes del lugar exacto
            que sean simples y contextualicen, por ejemplo, país o ciudad donde sucede lo hablado en el artículo, 
            no uses nombres propios de personas sino utiliza una alternativa, por ejemplo, su puesto de trabajo, su hobbie, su personalidad. 
            Recuerda siempre que los buscadores de imágenes necesitan palabras clave muy sencillas y generales para poder ser eficientes. 
            Recuerda que las imágenes deben ser horizontales: "${title ? title + ': ' : ''}
            ${meta_description ? meta_description : ''}"` 
          }],
          max_tokens: 10,
          temperature: 0.5,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.message || 'Error al obtener la palabra clave de la API');
      }

      const data = await response.json();
      query = data.choices[0].message.content.trim();
      console.log('Palabra clave generada:', query);

    } catch (error) {
      setError(`Error al generar palabra clave: ${error.message}`);
      console.error('Error al generar palabra clave:', error);
      setGeneratingImage(false);
      return;
    }
  }

  try {
    let imageUrl = '';
    if (source === 'pexels') {
      // Para Pexels
      const response = await pexelsClient.photos.search({
        query,
        per_page: 1,
        page: randomPage,
        orientation: 'landscape'
      });

      if (response.photos.length > 0) {
        imageUrl = response.photos[0].src.medium;
      } else {
        setError('No se encontraron imágenes en Pexels.');
        setGeneratingImage(false);
        return;
      }
    } else if (source === 'dalle') {
      // Para DALL-E
      const dalleResponse = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "dall-e-3",
          prompt: `Este es mi artículo "${title}": "${meta_description}". Dame una imagen que lo represente.`,
          n: 1,
          size: '1792x1024',
          quality: "standard",
        }),
      });

      if (!dalleResponse.ok) {
        const errorData = await dalleResponse.json();
        throw new Error(errorData.error.message || 'Error al generar la imagen con DALL-E');
      }

      const dalleData = await dalleResponse.json();
      imageUrl = dalleData.data[0].url;
      console.log('Imagen generada por DALL-E:', imageUrl);
    }
console.log('Imagen generada por', source, ':', imageUrl);


    
    // Guardar la imagen de forma permanente
    const permanentImageUrl = await saveImagePermanently(imageUrl);
    console.log('Guardando imagen permanentemente:', permanentImageUrl);

    // Actualiza la nota con la URL de la imagen
    const note = notes[selectedNote];
    if (note && note.id) {
      const updatedNote = { ...note, image_url: permanentImageUrl };
      await api.put(`/notes/${note.id}`, updatedNote);  // Actualiza la nota en el backend

      setNotes((prevNotes) =>
        prevNotes.map((n, index) =>
          index === selectedNote ? { ...n, image_url: permanentImageUrl } : n
        )
      );
      setGeneratedImageUrl(permanentImageUrl);  // Actualizar la URL de la imagen en el frontend
    }
  } catch (error) {
    setError(`Error al buscar imágenes: ${error.message}`);
    console.error('Error al buscar imágenes:', error);
  } finally {
    setGeneratingImage(false);
  }
};
  
useEffect(() => {
  if (selectedNote !== null) {
    const note = notes[selectedNote];
    setTitle(note?.title || '');
    setMetaDescription(note?.meta_description || ''); // Cambiado a meta_description
    setIntroduction(note?.introduction || '');
    setTags(note?.tags || '');
    setKeywords(note?.keywords || '');
    setContent(note?.content || '');
    setConclusion(note?.conclusion || '');
    setGeneratedImageUrl(note?.image_url || '');
  }
}, [selectedNote, notes]);

if (!Array.isArray(notes)) {
  return <div>Error: notes no es un array.</div>;
}


  return (
    <div className={`simple-generator-container ${isDarkMode ? 'dark' : ''}`}>
      <Helmet>
        <title>Generador Simple de Artículos</title>
        <meta name="description" content="Genera artículos completos con un solo prompt utilizando la API de OpenAI" />
      </Helmet>
      <header className={`App-header ${isDarkMode ? 'dark' : ''}`}>
        <div className="compact-form">

          <div className={`input-group ${isDarkMode ? 'dark' : ''}`}>
            <label>Sobre qué te gustaría que hable tu artículo:</label>
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Escribe el tema del artículo"
            />
          </div>
          <button   className={`button ${isDarkMode ? 'dark' : ''}`} onClick={generateArticle} disabled={loading}>
            {loading ? 'Generando...' : 'Generar Artículo'}
          </button>
          {loading && (
            <div className="loading-bar" style={{ marginTop: '20px' }}>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${progress}%` }}></div>
              </div>
              <p> Generando artículo...</p>
            </div>
          )}
          {error && <p className="error">{error}</p>}
        </div>
        {selectedNote !== null && notes[selectedNote] && (
          <div className={`note-container ${isDarkMode ? 'dark' : ''}`}>
{generatedImageUrl && (
  <img
    src={fullImageUrl}
    alt="Imagen generada"
  />
)}

            {isEditing ? (
              <>
                <div className="input-group">
                  <label>Título:</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Meta Description:</label>
                  <input
                    type="text"
                    value={meta_description} // Cambiado a meta_description
                    onChange={(e) => setMetaDescription(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Etiquetas:</label>
                  <input
                    type="text"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Palabras clave:</label>
                  <input
                    type="text"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Introducción:</label>
                  <textarea
                    value={introduction}
                    onChange={(e) => setIntroduction(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Contenido:</label>
                  <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label>Conclusión:</label>
                  <textarea
                    value={conclusion}
                    onChange={(e) => setConclusion(e.target.value)}
                  />
                </div>
                <button onClick={saveChanges}>Guardar Cambios</button>
              </>
            ) : (
              <>
                              <p><strong>Meta Description:</strong> {meta_description}</p> {/* Cambiado a meta_description */}
                <p><strong>Etiquetas:</strong> {tags}</p>
                <p><strong>Palabras clave:</strong> {keywords}</p>
<div dangerouslySetInnerHTML={{ __html: `
  <h2>${title}</h2>
  <p>${introduction}</p>
  ${content}
  <p>${conclusion}</p>
` }} />

                <button   className={`button ${isDarkMode ? 'dark' : ''}`} onClick={handleGenerateImage} disabled={generatingImage}>
  {generatingImage ? 'Generando imagen...' : 'Generar Imagen con Pexels'}
</button>
<button   className={`button ${isDarkMode ? 'dark' : ''}`} onClick={() => handleGenerateImage(false, 'dalle')} disabled={generatingImage}>
  {generatingImage ? 'Generando imagen...' : 'Generar Imagen con DALL-E'}
</button>

                <button   className={`button ${isDarkMode ? 'dark' : ''}`} onClick={() => setShowWordpressFields(!showWordpressFields)}>
                  {showWordpressFields ? 'Ocultar Publicación en WordPress' : 'Publicar en WordPress'}
                </button>
                {showWordpressFields && (
                  <div className="wordpress-credentials">
                    <div className="input-group">
                      <label>URL de WordPress:</label>
                      <input
                        type="text"
                        value={wordpressUrl}
                        onChange={(e) => setWordpressUrl(e.target.value)}
                        placeholder="https://tu-sitio.com"
                      />
                    </div>
                    <div className="input-group">
                      <label>Nombre de Usuario de WordPress:</label>
                      <input
                        type="text"
                        value={wordpressUsername}
                        onChange={(e) => setWordpressUsername(e.target.value)}
                      />
                    </div>
                    <div className="input-group">
                      <label>Contraseña de Aplicación de WordPress:</label>
                      <input
                        type="password"
                        value={wordpressAppPassword}
                        onChange={(e) => setWordpressAppPassword(e.target.value)}
                      />
                    </div>
                    <button onClick={publishToWordpress} disabled={isPublishing}>
  {isPublishing ? 'Publicando...' : 'Publicar en WordPress'}
</button>

                  </div>
                )}
                <button   className={`button ${isDarkMode ? 'dark' : ''}`} onClick={() => setIsEditing(true)}>Editar</button>
                <button className='delete-note' onClick={handleDeleteNote}>Eliminar</button> {/* Botón de eliminar */}
              </>
            )}
          </div>
        )}
      </header>
    </div>
  );
};

export default SimpleGenerator;
