import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { forgotPassword } from '../services/api'; // Importa la función para la solicitud de restablecimiento de contraseña
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await forgotPassword(email);
      setMessage(response.data.message);
      setError('');
      // Redirige a la página de inicio de sesión después de enviar la solicitud
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setError('Error al enviar la solicitud de restablecimiento de contraseña');
      setMessage('');
      console.error('Error al enviar la solicitud de restablecimiento de contraseña:', error);
    }
  };

  return (
    <div className="forgot-password-container">
      <form onSubmit={handleSubmit} className="forgot-password-form">
        <h2>Restablecer Contraseña</h2>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="reset-button">Enviar Solicitud</button>
        {error && <p className="error-message">{error}</p>}
        {message && <p className="success-message">{message}</p>}
        <p>¿Ya te acordaste? <Link to="/login">Iniciar Sesión</Link></p>
      </form>
    </div>
  );
};

export default ForgotPassword;
