import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import api from '../services/api';
import './Login.css';

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { email, password });
      const token = response.data.token;
      const userId = response.data.user_id;
  
      if (token && userId) {
        localStorage.setItem('token', token);
        localStorage.setItem('user_id', userId);  // Verifica que esto esté funcionando correctamente
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setIsAuthenticated(true);
        navigate('/simple');
      } else {
        throw new Error('Error durante el login.');
      }
    } catch (error) {
      console.error('Error en el login:', error);
    }
  };
  

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Iniciar Sesión</h2>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">Iniciar Sesión</button>
        <p>¿No tienes una cuenta? <Link to="/register">Regístrate</Link></p>
        <p><Link to="/forgot-password">¿Olvidaste tu contraseña?</Link></p>
      </form>
    </div>
  );
};

export default Login;
