import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import Modal from 'react-modal';
import SimpleGenerator from './SimpleGenerator';
import AdvancedGenerator, { formatContent } from './AdvancedGenerator';
import Estructura from './Estructura';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import VerifyEmail from './components/VerifyEmail';
import api, { deleteSubnote } from './services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import { createClient as createPexelsClient } from 'pexels';
import { createApi as createUnsplashApi } from 'unsplash-js';


const OPENAI_API_KEY = 'sk-proj-XKRex8fCQqVh0kOj6ONST3BlbkFJBoVvNt8wqPzMzQPbnezJ';
const PEXELS_API_KEY = 'biqKY8cKzZQZcANkk3MoR9Ft5TUCXLGVMDcOvOJWXyyAevkSvyWB1dMt';
const UNSPLASH_ACCESS_KEY = 'ufoE9if3wnET62ihy9cpt96W-z5oOlAxNM3EuSaMAKQ';

const pexelsClient = createPexelsClient(PEXELS_API_KEY);
const unsplash = createUnsplashApi({
  accessKey: UNSPLASH_ACCESS_KEY,
});
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [structures, setStructures] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState(null);
  const [showUpdates, setShowUpdates] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [expandedStructureIndex, setExpandedStructureIndex] = useState(null);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Modal state
  const [previewContent, setPreviewContent] = useState('');  // Content for modal
  const [editedContent, setEditedContent] = useState('');    // Editable content for modal

  const [isEditing, setIsEditing] = useState(false);  // Para el modo de edición
const [generatedImageUrl, setGeneratedImageUrl] = useState('');  // Para almacenar la imagen generada
const [customImageQuery, setCustomImageQuery] = useState('');  // Para consultas de imagen personalizadas
const [generatingImage, setGeneratingImage] = useState(false);  // Para manejar el estado de generación de imagen
const [error, setError] = useState('');
const [title, setTitle] = useState(''); // Título del artículo
const [meta_description, setMetaDescription] = useState(''); // Meta descripción
const [keywords, setKeywords] = useState([]); // Para almacenar las palabras clave
const [tags, setTags] = useState([]); // Para almacenar las etiquetas


const [introduction, setIntroduction] = useState('');  // Para la introducción
const [content, setContent] = useState('');  // Para el contenido principal
const [conclusion, setConclusion] = useState('');  // Para la conclusión

  // Estado para manejar las variables de WordPress
  const [wordpressUrl, setWordpressUrl] = useState('');
  const [wordpressUsername, setWordpressUsername] = useState('');
  const [wordpressAppPassword, setWordpressAppPassword] = useState('');
  const [isPublishing, setIsPublishing] = useState(false);
  const [showWordpressFields, setShowWordpressFields] = useState(false);

const fullImageUrl = generatedImageUrl.startsWith('http')
  ? generatedImageUrl
  : `https://databasecgpt.mktmarketingdigital.com/api/storage/images/${generatedImageUrl}`;




  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');
  
    if (token && userId) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true); // El usuario está autenticado
    } else {
      setIsAuthenticated(false); // El usuario no está autenticado
    }
  }, []);
  
  
  

  const handlePreview = (note, subtema, setPreviewContent, setEditedContent, setIsPreviewOpen) => {
    // Verificar si estamos mostrando el contenido de un subtema
    const content = subtema?.content || note?.content || 'No hay contenido disponible';
  
    // Establecer el título, meta descripción, palabras clave y etiquetas
    setTitle(note?.title || 'Sin título');
    setMetaDescription(note?.meta_description || 'Sin descripción');
    setKeywords(note?.keywords || []); // Configurar las palabras clave si existen
    setTags(note?.tags || []); // Configurar las etiquetas si existen
    
    // Configurar la introducción, contenido y conclusión
    setIntroduction(note?.introduction || 'Sin introducción');  // Agregar la introducción
    setContent(note?.content || 'Sin contenido');  // Agregar el contenido
    setConclusion(note?.conclusion || 'Sin conclusión');  // Agregar la conclusión
    
    console.log('Previsualización activada con contenido:', content);
    
    setPreviewContent(content);
    setEditedContent(content); // Mantener para edición si es necesario
    setIsPreviewOpen(true);
  };
  
  

const handleDeleteSubnote = async (noteId) => {
  try {
    await deleteSubnote(noteId);  // Llamar la función que elimina la subnota en el backend

    // Actualizar el estado de las notas después de la eliminación
    const updatedNotes = notes.filter((note) => note.id !== noteId);
    setNotes(updatedNotes);

    // Actualizar localStorage y sessionStorage
    localStorage.setItem('notes', JSON.stringify(updatedNotes));
    sessionStorage.setItem('notes', JSON.stringify(updatedNotes));

    setIsPreviewOpen(false);  // Cerrar el modal
    alert('Subnota eliminada correctamente.');
  } catch (error) {
    console.error('Error al eliminar la subnota:', error);
    alert('Error al eliminar la subnota.');
  }
};


const handleGenerateImage = async (useCustomQuery = false, source = 'pexels') => {
  setGeneratingImage(true);
  setGeneratedImageUrl(''); // Limpiar la URL de la imagen mientras se genera
  setError('');

  let query = '';
  let randomPage = Math.floor(Math.random() * 10) + 1; // Generar un número de página aleatorio entre 1 y 10

  if (useCustomQuery && customImageQuery.trim() !== '') {
    query = customImageQuery.trim();
  } else {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          messages: [{
            role: 'user',
            content: `Lee el siguiente artículo y genera una palabra clave o dos palabras 
            (en lo posible siempre una palabra sola, solo utiliza más de una palabra si es demasiado necesario) 
            que me sirvan como query para buscar una imagen adecuada para el articulo, si es necesario contextualiza, 
            o utiliza otras palabras que no estén en el artículo, que sirvan para buscar una imagen referida al artículo, 
            si se refiere a locaciones intentá ser lo más exacto posible para poder encontrar imágenes del lugar exacto
            que sean simples y contextualicen, por ejemplo, país o ciudad donde sucede lo hablado en el artículo, 
            no uses nombres propios de personas sino utiliza una alternativa, por ejemplo, su puesto de trabajo, su hobbie, su personalidad. 
            Recuerda siempre que los buscadores de imágenes necesitan palabras clave muy sencillas y generales para poder ser eficientes. 
            Recuerda que las imágenes deben ser horizontales: "${title}", "${meta_description}"`
          }],
          max_tokens: 10,
          temperature: 0.5,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.message || 'Error al obtener la palabra clave de la API');
      }

      const data = await response.json();
      query = data.choices[0].message.content.trim();
      console.log('Palabra clave generada:', query);

    } catch (error) {
      setError(`Error al generar palabra clave: ${error.message}`);
      console.error('Error al generar palabra clave:', error);
      setGeneratingImage(false);
      return;
    }
  }

  try {
    let imageUrl = '';
    if (source === 'pexels') {
      const response = await pexelsClient.photos.search({
        query,
        per_page: 1,
        page: randomPage,
        orientation: 'landscape' // Asegurarse de que la imagen sea horizontal
      });

      if (response.photos.length > 0) {
        imageUrl = response.photos[0].src.medium;
      } else {
        setError('No se encontraron imágenes en Pexels.');
        setGeneratingImage(false);
        return;
      }
    } else if (source === 'dalle') {
      const dalleResponse = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "dall-e-3",
          prompt: `Este es mi artículo titulado "${title}" con la meta descripción: "${meta_description}". Dame una imagen que lo represente.`,
          n: 1,
          size: '1792x1024', // Tamaño de la imagen para DALL-E
          quality: "standard",
        }),
      });

      if (!dalleResponse.ok) {
        const errorData = await dalleResponse.json();
        throw new Error(errorData.error.message || 'Error al generar la imagen con DALL-E');
      }

      const dalleData = await dalleResponse.json();
      imageUrl = dalleData.data[0].url;
      console.log('Imagen generada por DALL-E:', imageUrl);
    }


    // Guardar la imagen de forma permanente en el backend
    const permanentImageUrl = await saveImagePermanently(imageUrl);
    console.log('Guardando imagen permanentemente:', permanentImageUrl);

    // Establecer la URL de la imagen generada
    setGeneratedImageUrl(permanentImageUrl);

    // Actualizar subnota en lugar de nota
    if (selectedNote && selectedNote.generatorType === 'advanced') {
      const updatedSubnote = { ...selectedNote, image_url: permanentImageUrl };
      await api.put(`/subnotes/${selectedNote.id}/image-url`, updatedSubnote);

// Actualiza el estado local
setNotes((prevNotes) =>
prevNotes.map((n) => (n.id === selectedNote.id ? updatedSubnote : n))
);
setGeneratedImageUrl(permanentImageUrl);  // Actualiza la imagen en el frontend
    }

  } catch (error) {
    setError(`Error al buscar imágenes: ${error.message}`);
    console.error('Error al buscar imágenes:', error);
  } finally {
    setGeneratingImage(false);
  }
};

// Función para guardar la imagen permanentemente en el backend
const saveImagePermanently = async (imageUrl) => {
  try {
    const response = await api.post('/save-image', { imageUrl }); // Asegúrate de que estás usando la ruta correcta aquí.
    return response.data.savedImageUrl; // Asegúrate de que el backend está devolviendo la URL correcta.
  } catch (error) {
    console.error("Error al guardar la imagen permanentemente:", error);
  }
};


const saveEditedContent = async () => {
  const token = localStorage.getItem('token'); // Obtener el token del localStorage
  const userId = localStorage.getItem('user_id'); // Obtener el user_id del localStorage

  if (!userId) {
    console.error('User ID no encontrado.');
    setError('User ID no encontrado. Asegúrate de haber iniciado sesión correctamente.');
    return;
  }

  const updatedSubnotes = notes.map((note) => {
    if (note.id === selectedNote.id) {
      return { 
        ...note, // Mantiene todos los datos actuales de la nota, incluido el user_id
        title,
        meta_description, // Asegúrate de que esté actualizado correctamente
        introduction,
        tags,
        keywords,
        content,
        conclusion,
        image_url: generatedImageUrl,
        user_id: userId, // Asegurarse de no perder el user_id al actualizar
      };
    }
    return note;
  });

  try {
    // Aquí estamos preparando todos los campos para la solicitud PUT
    const dataToUpdate = {
      title,
      meta_description, // Asegúrate de que esté actualizado correctamente
      introduction,
      tags,
      keywords,
      content,
      conclusion,
      user_id: userId, // Asegurarse de no perder el user_id al actualizar
    };

    console.log('Datos que se enviarán:', dataToUpdate);

    const response = await api.put(`/subnotes/${selectedNote.id}`, dataToUpdate, {
      headers: {
        Authorization: `Bearer ${token}`, // Usar el token almacenado
      },
    });

    console.log('Respuesta del backend:', response); // Log para ver la respuesta del servidor

    if (response.status === 200) {
      setNotes(updatedSubnotes); // Actualizar el estado de las subnotas
      console.log('Contenido de la subnota actualizado con éxito.');
    } else {
      setError('Error al actualizar el contenido de la subnota.');
      console.error('Error en la respuesta:', response);
    }
  } catch (error) {
    setError('Error al actualizar el contenido de la subnota.');
    console.error('Error al actualizar el contenido de la subnota:', error);
  }

  setIsEditing(false);
  setIsPreviewOpen(false);
};




  // Verificar si el token existe en localStorage al iniciar la aplicación
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
      console.log('Usuario autenticado, token encontrado:', token);
    } else {
      setIsAuthenticated(false);
      console.log('No se encontró token, usuario no autenticado');
    }
    setIsAuthChecked(true); // Indicar que la verificación de autenticación ha sido completada
  }, []);
  
  
  // Log adicional para verificar cuando cambia isAuthenticated
  useEffect(() => {
    console.log('isAuthenticated cambiado:', isAuthenticated);
  }, [isAuthenticated]);
  
  
  const fetchNotes = async () => {
    try {
        const userId = localStorage.getItem('user_id');
        if (!userId) {
            console.error('User ID no encontrado en localStorage.');
            return;
        }

        // Log para verificar userId
        console.log('User ID obtenido de localStorage:', userId);

        // Obtener notas normales
        const notesResponse = await api.get(`/notes?user_id=${userId}`);
        console.log('Respuesta de notas:', notesResponse); // Añadir log para verificar respuesta
        const notesData = Array.isArray(notesResponse.data) ? notesResponse.data : [];

        // Obtener subnotas (subnotes)
        const subnotesResponse = await api.get(`/subnotes?user_id=${userId}`);
        console.log('Respuesta de subnotas:', subnotesResponse); // Añadir log para verificar respuesta
        const subnotesData = Array.isArray(subnotesResponse.data) ? subnotesResponse.data : [];

        // Asegúrate de que las subnotas tengan el campo generatorType establecido
        const subnotesWithGeneratorType = subnotesData.map(subnote => ({
            ...subnote,
            generatorType: 'advanced'
        }));

        // Combinar ambas listas
        const combinedNotes = [...notesData, ...subnotesWithGeneratorType];

        console.log('Notas combinadas (incluyendo subnotas):', combinedNotes); // Añadir log para verificar el resultado final

        // Actualizar el estado
        setNotes(combinedNotes);

        // Guardar en localStorage y sessionStorage
        localStorage.setItem('notes', JSON.stringify(combinedNotes));
        sessionStorage.setItem('notes', JSON.stringify(combinedNotes));

    } catch (error) {
        console.error('Error fetching notes:', error);
        setNotes([]); // En caso de error, vaciar el estado de notas
    }
};

useEffect(() => {
    console.log('Notas almacenadas en el estado:', notes);
}, [notes]);

useEffect(() => {
    const subnotes = notes.filter(note => note.generatorType === 'advanced');
    console.log('Subnotas obtenidas del backend:', subnotes);
}, [notes]);


  const fetchStructures = async () => {
    const userId = localStorage.getItem('user_id');
  
    try {
      console.log('Fetching structures for user_id:', userId);  // Log para verificar user_id
      const response = await api.get(`/structures?user_id=${userId}`);

      console.log('Response from /structures API:', response.data);  // Log para verificar la respuesta del servidor
  
      if (response.status === 200) {
        setStructures(response.data);
      } else {
        console.error('Error al obtener las estructuras:', response.statusText);
      }
    } catch (error) {
      console.error('Error al obtener las estructuras:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchNotes();
      fetchStructures();
    }
  }, [isAuthenticated]);
  
  // Verifica si `structures` tiene datos correctos
  useEffect(() => {
    console.log('Estructuras almacenadas en el estado:', structures);
  }, [structures]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    delete api.defaults.headers.common['Authorization'];
    setIsAuthenticated(false);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleUpdates = () => {
    setShowUpdates(!showUpdates);
  };

  const ProtectedRoute = ({ children }) => {
    console.log('Verificando autenticación en ProtectedRoute:', isAuthenticated);
    
    // Si aún no se ha verificado la autenticación, no renderizar nada
    if (!isAuthChecked) {
      return null;
    }
  
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
  
    return children;
  };
  
  const handleStructureSelect = (index) => {
    setSelectedStructure(index);
  };
  
  useEffect(() => {
    if (generatedImageUrl && selectedNote && selectedNote.image_url !== generatedImageUrl) {
      // Actualiza la imagen en el selectedNote y el modal solo si es necesario
      const updatedNote = { ...selectedNote, image_url: generatedImageUrl };
      setSelectedNote(updatedNote);
  
      // Actualiza el estado de las notas para reflejar la nueva imagen solo si es necesario
      setNotes((prevNotes) => 
        prevNotes.map((note) => 
          note.id === selectedNote.id && note.image_url !== generatedImageUrl ? updatedNote : note
        )
      );
    }
  }, [generatedImageUrl, selectedNote, setNotes]);
  
  const publishToWordpress = async () => {
    setIsPublishing(true);
  
    const createOrGetTags = async (tagNames) => {
      const tagIds = [];
      for (const tagName of tagNames) {
        try {
          console.log(`Buscando o creando la etiqueta: ${tagName}`);
          let response = await fetch(`${wordpressUrl}/wp-json/wp/v2/tags?search=${encodeURIComponent(tagName)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
            },
          });
  
          let tags = await response.json();
  
          if (tags.length > 0) {
            console.log(`Etiqueta "${tagName}" encontrada con ID: ${tags[0].id}`);
            tagIds.push(tags[0].id);
          } else {
            console.log(`Etiqueta "${tagName}" no encontrada, creando una nueva.`);
            response = await fetch(`${wordpressUrl}/wp-json/wp/v2/tags`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
              },
              body: JSON.stringify({ name: tagName }),
            });
  
            const newTag = await response.json();
            console.log(`Etiqueta "${tagName}" creada con ID: ${newTag.id}`);
            tagIds.push(newTag.id);
          }
        } catch (err) {
          console.error(`Error al crear/obtener la etiqueta "${tagName}":`, err);
          throw err;
        }
      }
      return tagIds;
    };
  
    const uploadFeaturedImage = async (imageUrl) => {
      if (!imageUrl) {
        console.log('No se encontró imagen en la nota, saltando la subida de imagen destacada.');
        return null;
      }
  
      try {
        console.log(`Subiendo imagen destacada desde URL: ${imageUrl}`);
        const response = await fetch(`${wordpressUrl}/wp-json/wp/v2/media`, {
          method: 'POST',
          headers: {
            'Content-Disposition': `attachment; filename=${title.replace(/\s+/g, '_')}.jpg`,
            'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
          },
          body: await fetch(imageUrl).then(res => res.blob())
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Error al subir la imagen a WordPress');
        }
  
        const mediaData = await response.json();
        console.log(`Imagen destacada subida con éxito. ID: ${mediaData.id}`);
        return mediaData.id;
      } catch (error) {
        console.error("Error al subir la imagen destacada:", error);
        throw error;
      }
    };
  
    try {
      console.log('Iniciando proceso de publicación de subnota a WordPress...');
  
      // Subir imagen solo si hay una imagen disponible
      const featuredMediaId = generatedImageUrl ? await uploadFeaturedImage(generatedImageUrl) : null;
  
      const tagNames = tags.split(',').map(tag => tag.trim());
      console.log(`Etiquetas a procesar: ${tagNames.join(', ')}`);
      const tagIds = await createOrGetTags(tagNames);
  
      // Asegurarnos de que keywords sea un array
      const keywordsArray = Array.isArray(keywords) ? keywords : keywords.split(',').map(keyword => keyword.trim());
  
      // Detalles de la subnota que se va a publicar
      console.log('Detalles de la subnota que se está publicando:', {
        title,
        meta_description,
        introduction,
        content,
        conclusion,
        keywords: keywordsArray,
        tags,
      });
  
      // Publicar la subnota en WordPress
      const post = {
        title: title,
        content: `${introduction}${content}${conclusion}`,
        status: 'draft', // Cambiar a 'publish' si se quiere publicar directamente
        tags: tagIds,
        featured_media: featuredMediaId, // Solo agregar si hay imagen
        meta: {
          // Metadatos para Rank Math (si se usa)
          rank_math_description: meta_description,
          rank_math_focus_keyword: keywordsArray.join(', '),
          // Metadatos para Yoast SEO
          yoast_wpseo_metadesc: meta_description, // Para Yoast
          yoast_wpseo_focuskw: keywordsArray.join(', '), // Para Yoast
        }
      };
  
      console.log('Publicando la subnota en WordPress...', post);
  
      const response = await fetch(`${wordpressUrl}/wp-json/wp/v2/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Basic ${btoa(`${wordpressUsername}:${wordpressAppPassword}`)}`,
        },
        body: JSON.stringify(post),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error en la respuesta de WordPress:', errorData);
        throw new Error(errorData.message || 'Error al publicar en WordPress');
      }
  
      const data = await response.json();
      console.log('Subnota subida como borrador en WordPress:', data);
      alert('Subnota subida como borrador en WordPress exitosamente');
    } catch (err) {
      setError(err.message || 'Error al subir como borrador en WordPress');
      console.error('Error en el proceso de publicación en WordPress:', err);
    } finally {
      setIsPublishing(false);
      console.log('Finalizado el proceso de publicación en WordPress');
    }
  };
  
  
  return (
    <Router>
      <div className={`App ${isDarkMode ? 'dark' : ''}`}>
        <nav className={`navbar ${isDarkMode ? 'dark' : 'light'}`}>
          <ul>
            <li>
            <Link to="/simple" onClick={() => setSelectedNote(null)}>Simple Prompt</Link>
            </li>
            <li>
  <Link to="/estructura" onClick={() => setSelectedStructure(null)}>Estructura</Link>
</li>
<li>
  <Link to="/advanced" onClick={() => setSelectedNote(null)}>Advanced Generator</Link>
</li>

          </ul>
          <div className="navbar-right">
            <button className='logout-button' onClick={toggleDarkMode}>
              {isDarkMode ? 'Modo Claro' : 'Modo Oscuro'}
            </button>
            <button  onClick={handleLogout} className="logout-button">
              <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar Sesión
            </button>
            <FontAwesomeIcon icon={faBell} className="updates-icon" onClick={toggleUpdates} />
          </div>
        </nav>
        <div className="main-container">
          <Routes>
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email/*" element={<VerifyEmail />} />
            <Route
              path="/"
              element={isAuthenticated ? <Navigate to="/simple" /> : <Login setIsAuthenticated={setIsAuthenticated} />}
            />
             <Route
  path="/simple"
  element={
    <ProtectedRoute>
      <>
        <aside className={`sidebar ${isDarkMode ? 'dark' : 'light'}`}>
          <h2 className='cosas-generadas'>Notas</h2>
          <ul>
            {notes.length > 0 ? (
              // Filtra las notas para excluir las que tienen generatorType 'advanced'
              notes
                .filter(note => note.generatorType !== 'advanced')
                .map((note, index) => (
                  <li key={index} onClick={() => setSelectedNote(index)}>
                    {note.title || 'Sin título'}
                  </li>
                ))
            ) : (
              <li>No hay notas disponibles</li>
            )}
          </ul>
        </aside>
        <div className={`content-area ${isDarkMode ? 'dark' : ''}`}>
          <SimpleGenerator
            isDarkMode={isDarkMode}
            notes={notes}
            setNotes={setNotes}
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
          />
        </div>
      </>
    </ProtectedRoute>
  }
/>
            <Route
              path="/estructura"
              element={
                <ProtectedRoute>
                  <>
                    <aside className={`sidebar ${isDarkMode ? 'dark' : 'light'}`}>
                      <h2 className='cosas-generadas'>Estructuras</h2>
                      <ul>
                        {structures.length > 0 ? (
                          structures.map((structure, index) => (
                            <li key={index} onClick={() => setSelectedStructure(index)}>
                              {structure.title || 'Sin título'}
                            </li>
                          ))
                        ) : (
                          <li>No hay estructuras disponibles</li>
                        )}
                      </ul>
                    </aside>
                    <div className={`content-area ${isDarkMode ? 'dark' : ''}`}>
                      <Estructura
                        isDarkMode={isDarkMode}
                        structures={structures}
                        setStructures={setStructures}
                        selectedStructure={selectedStructure}
                        setSelectedStructure={setSelectedStructure}
                      />
                    </div>
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/advanced"
              element={
                <ProtectedRoute>
                  <>
                    <aside className={`sidebar ${isDarkMode ? 'dark' : 'light'}`}>
                      <h2 className='cosas-generadas'>Subtemas</h2>
                      <ul>
                      {notes.length > 0 ? (
  notes.filter(note => note.generatorType === 'advanced').map((note, index) => (
    <li
      key={index}
      onClick={() => {
        const selectedSubtema = note.subtemas ? note.subtemas[0] : null; // Obtener el primer subtema o null si no hay
        console.log('Nota seleccionada:', note);
        handlePreview(note, selectedSubtema, setPreviewContent, setEditedContent, setIsPreviewOpen);
        setSelectedNote(note); // Asegúrate de pasar el objeto completo
      }}
    >
      {note.title || 'Nota sin título'}
    </li>
  ))
) : (
  <li>No hay notas avanzadas disponibles</li>
)}

</ul>

                    </aside>
                    <div className={`content-area ${isDarkMode ? 'dark' : ''}`}>
                      <AdvancedGenerator
                        isDarkMode={isDarkMode}
                        notes={notes}
                        setNotes={setNotes}
                        structures={structures}
                        setStructures={setStructures}
                        selectedStructure={selectedStructure}
                        setSelectedStructure={setSelectedStructure}
                        selectedNote={selectedNote}
                        setSelectedNote={setSelectedNote}
                        handlePreview={handlePreview}  // Pass the handlePreview function as prop
                      />
                    </div>
                  </>
                </ProtectedRoute>
              }
            />
            </Routes>

            
            <Modal
  isOpen={isPreviewOpen}
  onRequestClose={() => setIsPreviewOpen(false)}
  contentLabel="Preview Modal"
  className={`preview-modal ${isDarkMode ? 'dark' : ''}`}
  overlayClassName="preview-overlay"
>
<h2 className={`previsualizar ${isDarkMode ? 'dark' : ''}`}>
  Previsualización del Artículo
</h2>

  {/* Mostrar la imagen guardada si existe */}
  {selectedNote && selectedNote.image_url && (
    <div className="image-preview">
      <img src={selectedNote.image_url} alt="Imagen guardada" />
    </div>
  )}

  {isEditing ? (
    <>
      <div className="input-group">
        <label>Título:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Meta Description:</label>
        <input
          type="text"
          value={meta_description} 
          onChange={(e) => setMetaDescription(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Etiquetas:</label>
        <input
          type="text"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Palabras clave:</label>
        <input
          type="text"
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Introducción:</label>
        <textarea
          value={introduction}
          onChange={(e) => setIntroduction(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Contenido:</label>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>
      <div className="input-group">
        <label>Conclusión:</label>
        <textarea
          value={conclusion}
          onChange={(e) => setConclusion(e.target.value)}
        />
      </div>
    </>
  ) : (
    <div>
      <h2>{title || 'Sin título'}</h2>
      <p><strong>Meta Description:</strong> {meta_description}</p> {/* Cambiado a meta_description */}
      <p><strong>Etiquetas:</strong> {tags}</p>
      <p><strong>Palabras clave:</strong> {keywords}</p>
      <div dangerouslySetInnerHTML={{ __html: `
        <p>${introduction}</p>
        ${content}
        <p>${conclusion}</p>
      ` }} />
    </div>
  )}

  <div className="modal-buttons">
    <button onClick={() => setIsPreviewOpen(false)}>Cerrar</button>

    {isEditing ? (
      <button onClick={saveEditedContent}>Guardar</button>
    ) : (
      <>
        <button onClick={() => setIsEditing(true)}>Editar</button>
        <button onClick={handleGenerateImage} disabled={generatingImage}>
          {generatingImage ? 'Generando imagen...' : 'Generar Imagen con Pexels'}
        </button>
        <button onClick={() => handleGenerateImage(false, 'dalle')} disabled={generatingImage}>
          {generatingImage ? 'Generando imagen...' : 'Generar Imagen con DALL-E'}
        </button>
        
        <button onClick={() => setShowWordpressFields(!showWordpressFields)}>
          {showWordpressFields ? 'Ocultar Publicación en WordPress' : 'Publicar en WordPress'}
        </button>

        {showWordpressFields && (
          <div className="wordpress-credentials">
            <div className="input-group">
              <label>URL de WordPress:</label>
              <input
                type="text"
                value={wordpressUrl}
                onChange={(e) => setWordpressUrl(e.target.value)}
                placeholder="https://tu-sitio.com"
              />
            </div>
            <div className="input-group">
              <label>Nombre de Usuario de WordPress:</label>
              <input
                type="text"
                value={wordpressUsername}
                onChange={(e) => setWordpressUsername(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Contraseña de Aplicación de WordPress:</label>
              <input
                type="password"
                value={wordpressAppPassword}
                onChange={(e) => setWordpressAppPassword(e.target.value)}
              />
            </div>
            <button onClick={publishToWordpress} disabled={isPublishing}>
              {isPublishing ? 'Publicando...' : 'Publicar en WordPress'}
            </button>
          </div>
        )}

        <button 
          onClick={() => selectedNote && handleDeleteSubnote(selectedNote.id)} 
          className="delete-button"
        >
          Eliminar Subnota
        </button>
      </>
    )}
  </div>
</Modal>


          {showUpdates && (
            <div className={`updates ${isDarkMode ? 'dark' : ''}`}>
              <h4>Últimas Actualizaciones</h4>
              <ul>
              <li>Versión 2.0</li>
                <li>• Las imágenes generadas por pexels y por Dall-e se guardan en la base de datos, tanto en notas simples como avanzadas.</li>
                <li>• Editar notas desde la app, tanto simples como avanzadas.</li>
                <li>• Las notas avanzadas tienen metadescripción, keywords y etiquetas.</li>
                <li>• Las estructuras cargadas desde un excel externo se guardan en la base de datos</li>
                <li>• Se pueden subir a Wordpress tanto las notas simples como las avanzadas, quedan guardadas en "Entradas" en estado de borrador.</li>
                <li><br /></li>
              <li>Versión 1.0</li>
                <li>• Las notas simples, las estructuras generadas, y las notas avanzadas se guardan en la base de datos.</li>
                <li>• Proceso de registro de usuarios y login funcionales.</li>
                <li>• Sidebar para previsualizar notas avanzadas.</li>
                <li>• Posibilidad de eliminar notas simples, estructuras generadas y notas avanzadas.</li>
                <li><br /></li>
                <li>Versión 0.8.2</li>
                <li>• Arreglos / Mejoras en el SimpleGenerator: Tanto editar como generar imágenes se guardan en la base de datos.</li>
                <li>• Solución en el guardado de la Meta Descripción.</li>
                <li><br /></li>
                <li>Versión 0.8.0</li>
                <li>• Inicio de sesión y registro de usuarios.</li>
                <li>• Generación de imágenes con DALLE.</li>
                <li><br /></li>
                <li>Versión 0.7.0</li>
                <li>• Estructura: Ahora se pueden cargar archivos xlsx.</li>
                <li><br /></li>
                <li>Versión 0.6.0</li>
                <li>• Estructura: Ahora se pueden cargar archivos xlsx.</li>
                <li>• Generador Bulk: Ya se puede subir a WordPress, escribir de forma individual y mejoras en el prompt de los artículos.</li>
                <li><br /></li>
                <li>Versión 0.5.0</li>
                <li>• Mejora en el Generador de estructura: Mapa conceptual funcional.</li>
                <li>• Mejora en la redacción de Simple Prompt: Artículos mucho más largos.</li>
                <li><br /></li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </Router>
  );
  
};

export default App;
