import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './VerifyEmail.css';

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(
          `https://databasecgpt.mktmarketingdigital.com/api/email/verify`, {
            params: { token },
            headers: { 'Content-Type': 'application/json' }  // No Authorization header
          }
        );
        if (response.status === 200) {
          setMessage('Email verificado con éxito.');
        } else {
          setMessage('Error al verificar el correo electrónico.');
        }
      } catch (error) {
        console.error('Error al verificar el correo electrónico:', error);
        setMessage(`Enlace de verificación no válido. Error: ${error.response?.data?.message || error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setMessage('Enlace de verificación no válido.');
      setLoading(false);
    }
  }, [token]);

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <div className="verify-email-container">
      <div className="verify-email-content">
        {loading ? (
          <h2>Verificando tu correo electrónico...</h2>
        ) : (
          <>
            <h2>{message}</h2>
            {!loading && message === 'Email verificado con éxito.' && (
              <button onClick={handleLogin}>Iniciar sesión</button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
