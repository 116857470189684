import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
} from 'react-flow-renderer';
import { Menu, MenuItem, ControlledMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { useNavigate, useLocation } from 'react-router-dom';  // <-- Importa useLocation aquí
import dagre from 'dagre';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import { createStructure, getStructures, deleteStructure, updateStructure } from './services/api';
import './App.css';


const OPENAI_API_KEY = 'sk-proj-XKRex8fCQqVh0kOj6ONST3BlbkFJBoVvNt8wqPzMzQPbnezJ'; // Tu clave API

const Estructura = ({
  isDarkMode,
  structures,
  setStructures,
  selectedStructure,
  setSelectedStructure,
}) => {
  const [prompt, setPrompt] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [baseURL, setBaseURL] = useState('example.com');
  const [tree, setTree] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [contextNodeId, setContextNodeId] = useState(null);
  const [isEditingNode, setIsEditingNode] = useState(false);
  const [editedNodeLabel, setEditedNodeLabel] = useState('');
  const navigate = useNavigate();
  const location = useLocation();  // <-- Usa useLocation aquí


  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 172;
  const nodeHeight = 36;

  // Función para manejar la eliminación de la estructura
  const handleDeleteStructure = async () => {
    const structure = structures[selectedStructure];
    if (!structure || !structure.id) {
      console.error('ID de la estructura no disponible. No se puede eliminar.');
      setError('ID de la estructura no disponible. No se puede eliminar.');
      return;
    }
  
    try {
      await deleteStructure(structure.id);  // Llamada a la API para eliminar la estructura
      setStructures(prevStructures => prevStructures.filter(s => s.id !== structure.id));  // Actualiza el estado de las estructuras
      setSelectedStructure(null);  // Des-selecciona la estructura
      console.log('Estructura eliminada con éxito.');
      alert('Estructura eliminada');  // Mostrar alerta al usuario
    } catch (error) {
      console.error('Error al eliminar la estructura:', error);
      setError('Error al eliminar la estructura.');
    }
  };
  
  const getLayoutedElements = (nodes, edges) => {
    dagreGraph.setGraph({ rankdir: 'LR' });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = 'left';
      node.sourcePosition = 'right';

      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      node.draggable = false;

      return node;
    });

    return { nodes, edges };
  };

  const generateTree = async () => {
    setLoading(true);
    setError('');
  
    try {
      const completePrompt = `
        Crea un árbol de temas y subtemas para un blog sobre el siguiente tema: "${prompt}".
        Información adicional: "${additionalInfo}".
        La estructura debe estar en formato JSON.
        Estructura del JSON:
        {
          "tema": "Tema Principal",
          "subtemas": [
            {
              "tema": "Subtema 1",
              "subtemas": [
                {
                  "tema": "Sub-subtema 1.1",
                  "subtemas": []
                },
                {
                  "tema": "Sub-subtema 1.2",
                  "subtemas": []
                }
              ]
            },
            {
              "tema": "Subtema 2",
              "subtemas": [
                {
                  "tema": "Sub-subtema 2.1",
                  "subtemas": []
                },
                {
                  "tema": "Sub-subtema 2.2",
                  "subtemas": []
                }
              ]
            }
          ]
        }
        Asegúrate de incluir tantos niveles de profundidad y temas necesarios dependiendo del contenido.
      `;
  
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          messages: [{ role: 'user', content: completePrompt }],
          max_tokens: 2048,
          temperature: 0.5,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.message || 'Error al obtener la respuesta de la API');
      }
  
      const data = await response.json();
      let responseText = data.choices[0].message.content;
  
      responseText = responseText.replace(/```json|```/g, '');
  
      const parsedTree = JSON.parse(responseText);
  
      const updatedTree = addURLsToTree(parsedTree, baseURL);
  
      setTree(updatedTree);
  
      const newStructure = {
        title: prompt,
        additionalInfo: additionalInfo,
        baseURL: baseURL,
        tree: updatedTree,
        user_id: localStorage.getItem('user_id'), // Asegúrate de obtener y enviar el user_id
      };
  
      console.log("Datos enviados a la API:", JSON.stringify(newStructure, null, 2));  // Log detallado
  
      const token = sessionStorage.getItem('token');
      const createdStructure = await createStructure(newStructure, token);
      
  
      setStructures((prevStructures) => {
        const updatedStructures = [...prevStructures, { ...newStructure, id: createdStructure.id }];
        sessionStorage.setItem('structures', JSON.stringify(updatedStructures)); // Guárdalo en el sessionStorage
        setSelectedStructure(updatedStructures.length - 1);
        return updatedStructures;
      });
    
      const { newNodes, newEdges } = convertTreeToFlow(updatedTree);
      const layoutedElements = getLayoutedElements(newNodes, newEdges);
      setNodes(layoutedElements.nodes);
      setEdges(layoutedElements.edges);
    } catch (err) {
      setError(err.message || 'Error al generar la estructura de temas');
      console.error('Error al guardar la estructura en la base de datos:', err);
    } finally {
      setLoading(false);
    }
  };
  

  const addURLsToTree = (tree, baseURL, parentPath = '') => {
    const currentPath = `${parentPath}/${tree.tema.replace(/ /g, '-').toLowerCase()}`;
    tree.url = `${baseURL}${currentPath}`;

    if (!tree.subtemas) {
      tree.subtemas = [];
    }

    tree.subtemas.forEach((subtema) => {
      addURLsToTree(subtema, baseURL, currentPath);
    });

    return tree;
  };

  const convertTreeToFlow = (tree, parentId = null, depth = 0) => {
    const newNodes = [];
    const newEdges = [];
    const nodeId = `node-${parentId ? parentId : 'root'}-${depth}-${tree.tema}`;
  
    // Si es una categoría principal y estamos en la raíz, no queremos que se repita el tema principal
    if (depth > 0 || parentId === null) {
      newNodes.push({
        id: nodeId,
        type: 'default',
        data: { label: tree.tema },
        position: { x: 0, y: 0 },
        draggable: false,
      });
    }
  
    if (parentId) {
      newEdges.push({
        id: `edge-${parentId}-${nodeId}`,
        source: parentId,
        target: nodeId,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      });
    }
  
    if (tree.subtemas) {
      tree.subtemas.forEach((subtema, index) => {
        const { newNodes: childNodes, newEdges: childEdges } = convertTreeToFlow(
          subtema,
          nodeId,
          depth + 1
        );
        newNodes.push(...childNodes);
        newEdges.push(...childEdges);
      });
    }
  
  

    return { newNodes, newEdges };
  };

  useEffect(() => {
    if (selectedStructure !== null && structures.length > 0) {
        const structure = structures[selectedStructure];
        setTree(structure?.tree || null);
        setPrompt(structure?.title || '');
        setAdditionalInfo(structure?.additionalInfo || '');
        setBaseURL(structure?.baseURL || 'example.com');

        if (structure?.tree) {
            const parsedTree =
                typeof structure.tree === 'string' ? JSON.parse(structure.tree) : structure.tree;
            const { newNodes, newEdges } = convertTreeToFlow(parsedTree);
            const layoutedElements = getLayoutedElements(newNodes, newEdges);
            setNodes(layoutedElements.nodes);
            setEdges(layoutedElements.edges);
        }
    }
}, [selectedStructure, structures]);



  const handleMenuClick = (action) => {
    const node = nodes.find((n) => n.id === contextNodeId);
    const nodeLabel = node.data.label;

    if (action === 'edit') {
      setIsEditingNode(true);
      setEditedNodeLabel(nodeLabel);
    } else if (action === 'write') {
      navigate('/simple', { state: { prompt: `${nodeLabel}` } });
    }

    setContextMenuVisible(false);
  };

  const onNodeClick = (event, node) => {
    event.preventDefault();
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setContextNodeId(node.id);
    setContextMenuVisible(true);
  };

  const handleEditChange = (e) => {
    setEditedNodeLabel(e.target.value);
  };

  const handleEditSubmit = () => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === contextNodeId ? { ...node, data: { ...node.data, label: editedNodeLabel } } : node
      )
    );
    setIsEditingNode(false);
    setContextNodeId(null);
    setEditedNodeLabel('');
  };

  const fetchAndExportSelectedStructure = async () => {
    try {
      if (selectedStructure === null || structures.length === 0) {
        console.error('No hay estructura seleccionada para exportar.');
        return;
      }
  
      // Obtener la estructura seleccionada
      const structure = structures[selectedStructure];
      
      if (!structure || !structure.tree) {
        console.error('Estructura seleccionada inválida.');
        return;
      }
  
      const parsedTree = typeof structure.tree === 'string' ? JSON.parse(structure.tree) : structure.tree;
    
      // Exportar la estructura seleccionada a Excel
      exportStructureToExcel(parsedTree);
    } catch (error) {
      console.error('Error al exportar la estructura seleccionada:', error);
    }
  };
  
  const exportStructureToExcel = (tree) => {
    const flattenTree = (node, depth = 0, parentCategory = '', parentURL = '') => {
      let rows = [];
      if (depth > 0) {
        rows.push({
          Categoria: depth === 1 ? node.tema : parentCategory,
          'URL Categoria': depth === 1 ? node.url : parentURL,
          Subcategoria: depth === 2 ? node.tema : '',
          'URL Subcategoria': depth === 2 ? node.url : '',
          'Titulo URL': depth > 2 ? node.tema : '',
          URL: node.url,
        });
      }
  
      if (node.subtemas) {
        node.subtemas.forEach((subtema) => {
          rows = rows.concat(flattenTree(subtema, depth + 1, depth === 1 ? node.tema : parentCategory, depth === 1 ? node.url : parentURL));
        });
      }
      return rows;
    };
  
    const rows = flattenTree(tree);
  
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Estructura');
    XLSX.writeFile(workbook, 'estructura.xlsx');
  };
  

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      // Obtener el nombre del archivo (sin la extensión)
      const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
  
      const buildTree = (data) => {
        // Inicializamos el árbol con el tema principal usando siempre el nombre del archivo
        const root = { tema: fileNameWithoutExtension, subtemas: [] };
        const levels = { 0: root };
  
        data.slice(1).forEach((row) => {
          const [Categoria, URLCategoria, Subcategoria, URLSubcategoria, TituloURL, URL] = row;
  
          if (Categoria) {
            const node = { tema: Categoria, url: URLCategoria || '', subtemas: [] };
            levels[1] = node;
            levels[0].subtemas.push(node);
          }
          if (Subcategoria) {
            const node = { tema: Subcategoria, url: URLSubcategoria || '', subtemas: [] };
            levels[2] = node;
            levels[1].subtemas.push(node);
          }
          if (TituloURL) {
            const node = { tema: TituloURL, url: URL || '', subtemas: [] };
            levels[3] = node;
            levels[2].subtemas.push(node);
          }
        });
  
        return root;
      };
  
      const importedTree = buildTree(jsonData);
      
      // Asignar siempre el nombre del archivo como tema principal
      importedTree.tema = fileNameWithoutExtension;
      
      setTree(importedTree);
  
      const newStructure = {
        title: fileNameWithoutExtension, // Nombre del archivo como título
        additionalInfo: '',
        baseURL: baseURL,
        tree: importedTree,
        user_id: localStorage.getItem('user_id'), // Guardar el user_id
      };
  
      try {
        const token = sessionStorage.getItem('token');
        const createdStructure = await createStructure(newStructure, token); // Enviar a la base de datos
  
        setStructures((prevStructures) => {
          const updatedStructures = [...prevStructures, { ...newStructure, id: createdStructure.id }];
          localStorage.setItem('structures', JSON.stringify(updatedStructures));
          return updatedStructures;
        });
  
        setSelectedStructure(structures.length);
  
        const { newNodes, newEdges } = convertTreeToFlow(importedTree);
        const layoutedElements = getLayoutedElements(newNodes, newEdges);
        setNodes(layoutedElements.nodes);
        setEdges(layoutedElements.edges);
      } catch (error) {
        console.error('Error al guardar la estructura:', error);
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  

  const saveStructureToSessionStorage = (structure) => {
    sessionStorage.setItem('structure', JSON.stringify(structure));
  };
  
  const startWriting = () => {
    if (selectedStructure !== null) {
      const structureToSave = structures[selectedStructure];
      saveStructureToSessionStorage(structureToSave);
      
      navigate('/advanced', { state: { structure: structureToSave } });
    } else {
      console.error('No hay estructura seleccionada para redactar.');
    }
  };

  
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setStructures((prevStructures) =>
      prevStructures.map((structure, index) =>
        index === selectedStructure ? { ...structure, title: newTitle } : structure
      )
    );
  };
  
  const saveStructureChanges = async () => {
    const structureToSave = structures[selectedStructure];
    if (structureToSave && structureToSave.id) {
      try {
        const token = sessionStorage.getItem('token');
        await updateStructure(structureToSave, token); // Llama a la función para guardar los cambios
        console.log('Estructura actualizada correctamente');
      } catch (error) {
        console.error('Error al actualizar la estructura:', error);
      }
    }
  };
  
  return (
    <div className={`generator-container ${isDarkMode ? 'dark' : ''}`}>
      <Helmet>
        <title>Generador de Estructura de Temas</title>
        <meta name="description" content="Genera una estructura de temas y subtemas para un blog utilizando la API de OpenAI" />
      </Helmet>
      <header className={`App-header ${isDarkMode ? 'dark' : ''}`}>
        <div className="compact-form">
       
  
          <div className="input-group">
            <label>Tema del blog:</label>
            <input
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Escribe el tema principal"
            />
          </div>
          <div className="input-group">
            <label>Información adicional:</label>
            <textarea className={`input-group textarea ${isDarkMode ? 'dark' : ''}`}

              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              placeholder="Proporciona información adicional o indicaciones específicas"
            />
          </div>
          <div className="input-group">
            <label>URL Base:</label>
            <input
              type="text"
              value={baseURL}
              onChange={(e) => setBaseURL(e.target.value)}
              placeholder="example.com"
            />
          </div>
          <button   className={`button ${isDarkMode ? 'dark' : ''}`} onClick={generateTree} disabled={loading}>
            {loading ? 'Generando...' : 'Generar Estructura'}
          </button>
          {loading && <p>Cargando...</p>}
          {error && <p className="error">{error}</p>}
          <button   className={`button ${isDarkMode ? 'dark' : ''}`} onClick={fetchAndExportSelectedStructure}>
  Exportar a Excel
</button>

<div className="input-group">
  <label>Cargar archivo Excel:</label>
  <input
    className={`archivo ${isDarkMode ? 'dark' : ''}`}  // Añadir clase condicional
    type="file"
    accept=".xlsx, .xls"
    onChange={handleFileUpload}
  />
</div>

        </div>
        {tree && (
          <>
            <div style={{ height: 600 }}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                fitView
                onNodeContextMenu={onNodeClick}
                onNodeDragStop={(e, n) => e.preventDefault()}
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            </div>
            <ControlledMenu
              anchorPoint={contextMenuPosition}
              state={contextMenuVisible ? 'open' : 'closed'}
              onClose={() => setContextMenuVisible(false)}
            >
              <MenuItem onClick={() => handleMenuClick('edit')}>Editar</MenuItem>
              <MenuItem onClick={() => handleMenuClick('write')}>Redactar</MenuItem>
            </ControlledMenu>
            <Modal
              isOpen={isEditingNode}
              onRequestClose={() => setIsEditingNode(false)}
              contentLabel="Editar Nodo"
              className="edit-modal"
              overlayClassName="edit-modal-overlay"
            >
              <h2>Editar Nodo</h2>
              <div className="input-group">
                <label>Nombre del Nodo:</label>
                <input
                  type="text"
                  value={editedNodeLabel}
                  onChange={handleEditChange}
                />
              </div>
              <button onClick={handleEditSubmit}>Guardar</button>
            </Modal>
            <button onClick={startWriting}   className={`start-writing-button ${isDarkMode ? 'dark' : ''}`}>
              Empezar a Redactar
            </button>
            <button  onClick={handleDeleteStructure}   className={`delete-structure-button ${isDarkMode ? 'dark' : ''}`}>
              Eliminar Estructura
            </button> {/* Botón de eliminar estructura */}
          </>
        )}
      </header>
    </div>
  );
  
};

export default Estructura;
